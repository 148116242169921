import Echo from 'laravel-echo';

window.Pusher = require('pusher-js');

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.VUE_APP_WEBSOCKET_KEY,
    wsHost: process.env.VUE_APP_WEBSOCKET_SERVER,
    wsPort:process.env.VUE_APP_SOCKET_PORT,
    wssPort: process.env.VUE_APP_SOCKET_PORT,
    forceTLS: false,
    disableStats: true,
    enabledTransports: ['ws','wss'] // wss
});


export default window.Echo;
