import CustomerService from "@/core/services/car/CustomerService"

export async function useUpdateCustomerData(params) {
    return await CustomerService.withGlobal(params)
}

export async function useUpdateCustomerDataNew(params) {
    return await CustomerService.withGlobalNew(params)
}

