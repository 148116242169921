
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Mutation, Action, Module, VuexModule } from "vuex-module-decorators";
import ApiService from "@/core/services/ApiService";
import ISplitPaymentList from "@/models/car/ISplitPaymentList";
import IPaginationInfo from "@/models/IPaginationInfo";

import axios from "axios";
import { IInsurancePolicyList } from "@/models/car/IInsurancePolicyList";
import { notif } from '@/store/stateless/store';
import EmailService from "@/core/services/car/EmailService";

export interface SplitPaymentInfo {
  invoiceUrl: SplitPaymentUrlInfo;
}

export interface SplitPaymentUrlInfo {
  getSplitPaymentListUrl: string;
  updateSplitPaymentStatusUrl:string;
}
  
export interface SplitPaymentModalValue {
  "installment_id": number,
  "status": number,
  "payment_date": string,
  "cib_receipt":string,
}

@Module
export default class SplitPaymentModule extends VuexModule {
  splitPaymentsUrl = {} as SplitPaymentUrlInfo;
  paginationObj = {
    current_page: 1,
    data: [],
    first_page_url: "",
    from: 1,
    last_page: 1,
    last_page_url: "",
    links: [],
    next_page_url: "",
    path: "",
    per_page: "",
    prev_page_url: "",
    to: 1,
    total: 1,
  } as IPaginationInfo;
  errors = [];
  splitPayment = {} as ISplitPaymentList;
  splitPaymentList = {} as ISplitPaymentList;
  aiLoading = true;
  openSplitPaymentModal = false;
  openSplitPaymentModalValue = {} as SplitPaymentModalValue;
  isSplitPaymentModalLoading = false;
  splitPaymentUpdateErrors = [];
  @Action
  [Actions.GET_SPLIT_PAYMENT_LIST](payload) {
    this.context.commit(Mutations.SET_AI_LOADING, payload.reload);
    return new Promise<any>((resolve, reject) => {
      if(payload.reload) this.context.commit(Mutations.SET_SPLIT_PAYMENTS_DOM, false);
      this.splitPaymentsUrl.getSplitPaymentListUrl = (payload.index == 1) ? "skye/split-payment/list" : "skye/split-payment/list?page=" + payload.index;
      ApiService.post(this.splitPaymentsUrl.getSplitPaymentListUrl, payload)
        .then(({ data }) => {
          debugger
          this.context.commit(Mutations.SET_SPLIT_PAYMENTS_DOM, true);
          if(payload.reload) this.context.commit(Mutations.SET_AI_LOADING, false);
          this.context.commit(Mutations.SET_DI_LOADING, false);
          this.context.commit(Mutations.SET_SPLIT_PAYMENT_LIST, data.data.split_payments);
          resolve(data);
        })
        .catch(({ response }) => {// 
          this.context.commit(Mutations.SET_DI_LOADING, false);
          this.context.commit(Mutations.SET_AI_LOADING, false);
          this.context.commit(Mutations.SET_SPLIT_PAYMENT_LIST_ERROR, response);
          reject(response);
        });
    });
  }

  @Action
  [Actions.FETCH_SPLIT_PAYMENT_LIST](payload) {
    this.context.commit(Mutations.SET_IFA_LOADING, payload.reload);
    return new Promise<any>((resolve, reject) => { // 
      if(payload.reload) this.context.commit(Mutations.SET_SPLIT_PAYMENTS_DOM, false);
      this.splitPaymentsUrl.getSplitPaymentListUrl = (payload.index == 1) ? "skye/split-payment/list" : "skye/split-payment/list?page=" + payload.index;
      ApiService.post(this.splitPaymentsUrl.getSplitPaymentListUrl, payload)
        .then(({ data }) => {
          debugger
          
          this.context.commit(Mutations.SET_SPLIT_PAYMENTS_DOM, true);
          this.context.commit(Mutations.SET_IFA_LOADING, false);
          this.context.commit(Mutations.SET_SPLIT_PAYMENT_LIST, data.data.invoices);
          resolve(data);

        })
        .catch(({ response }) => {// 
          this.context.commit(Mutations.SET_IFA_LOADING, false);
          this.context.commit(Mutations.SET_SPLIT_PAYMENT_LIST_ERROR, response);
          reject(response);
        });
    });
  }

  @Action
  [Actions.UPDATE_SPLIT_PAYMENT_STATUS](payload) {
    return new Promise<any>((resolve, reject) => { // 
      this.splitPaymentsUrl.updateSplitPaymentStatusUrl = "/skye/split-payment/update/installment";
      ApiService.post(this.splitPaymentsUrl.updateSplitPaymentStatusUrl, payload)
        .then(({ data }) => { 
          this.context.commit(Mutations.SET_SPLIT_PAYMENT_UPDATE_ERROR, false);
          resolve(data);
        })
        .catch(({ response }) => {// 
          if(response.data){
            this.context.commit(Mutations.SET_SPLIT_PAYMENT_UPDATE_ERROR, response.data);
         }
          reject(response);
        });
    });
  }

  
  
  @Mutation
  [Mutations.SET_SPLIT_PAYMENT_LIST](payload) {
    // 
    this.splitPaymentList = payload.data;
    this.paginationObj = payload;
  }

  @Mutation
  [Mutations.SET_AI_LOADING](payload) {
    this.aiLoading = payload
  }

  @Mutation
  [Mutations.SET_SPLIT_PAYMENT_MODAL](payload) {
    this.openSplitPaymentModal = payload
  }
  @Mutation
  [Mutations.SET_SPLIT_PAYMENT_MODAL_LOADING]() {
    this.isSplitPaymentModalLoading = !this.isSplitPaymentModalLoading
  }
  @Mutation
  [Mutations.SET_SPLIT_PAYMENT_MODAL_VALUE](payload) {
    this.openSplitPaymentModalValue = payload
  }

  @Mutation
  [Mutations.SET_SPLIT_PAYMENT_LIST_ERROR](error) {
    this.errors = error.data.message;
  }

  @Mutation
  [Mutations.SET_SPLIT_PAYMENT_UPDATE_ERROR](error) {
    this.splitPaymentUpdateErrors = error.data;
  }

  
  get fetchSplitPayment():ISplitPaymentList {
    return this.splitPayment;
  }
  get getSplitPaymentsList():ISplitPaymentList{
    return this.splitPaymentList;
  }
  get getSplitPaymentListErrors(): Array<string> {
    return this.errors;
  }
  get getSplitPaymentPaginationObject() {
    return this.paginationObj;
  }

  get getSplitPaymentAiLoading() {
    return this.aiLoading;
  }

  get getSplitPaymentModal(): boolean {
    return this.openSplitPaymentModal
  }
  
  get getSplitPaymentModalValue(): SplitPaymentModalValue {
    return this.openSplitPaymentModalValue
  }
  
  get getSplitPaymentModalLoading(): boolean {
    return this.isSplitPaymentModalLoading
  }
  get getSplitPaymentUpdateErrors(): Array<string> {
    return this.splitPaymentUpdateErrors;
  }



}
