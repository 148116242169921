import { App, Component } from "vue";
import vcDatePicker from '@/components/reusables/vcDatePicker.vue'
import vcDatePickerTime from '@/components/reusables/vcDatePickerTime.vue'
import vcDateRange from '@/components/reusables/vcDateRange.vue'
import vcAmount from '@/components/reusables/vcAmount.vue'
import UpdateNameModal from '@/components/modals/forms/UpdateNameModal.vue'
import emiratesIDInput from '@/components/input/emiratesIDInput.vue'
import uploadDocuments from '@/components/modals/forms/uploadDocuments.vue'
import invoiceHasPending from '@/components/modals/forms/InvoiceHasPending.vue'
import extensionItem from '@/components/reusables/extensionUploadDocumentItem.vue'

// New Component
import vcTables from '@/components/reusables/NEW/vcTables.vue'
import vcDatePickerv2 from '@/components/reusables/NEW/vcDatePicker.vue'
import PasswordReset from "@/components/modals/PasswordReset.vue";


export function initSkyeComponent(app: App<Element>) {
    const data = [
        { key: 'vc-date-picker', component: vcDatePicker },
        { key: 'vc-date-picker-time', component: vcDatePickerTime },
        { key: 'vc-date-picker-range', component: vcDateRange },
        { key: 'vc-amount', component: vcAmount },
        { key: 'vue-input-mask', component: emiratesIDInput },
        { key: 'vc-update-name', component: UpdateNameModal },
        { key: 'vc-extension-update', component: uploadDocuments },
        { key: 'vc-invoice-has-pending', component: invoiceHasPending },
        { key: 'vc-extension-item', component: extensionItem },
        { key: 'vc-tables', component: vcTables },
        { key: 'vc-date-picker-v2', component: vcDatePickerv2 },
        { key: 'vc-modal-password-reset', component: PasswordReset },
    ]

    data.forEach(element => {
        app.component(element.key, element.component);
    })

}
