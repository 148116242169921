
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Mutation, Action, Module, VuexModule } from "vuex-module-decorators";
import ITaskResponse from "@/models/car/ITaskResponse";
import ApiService from "@/core/services/ApiService";
import IPaginationInfo from "@/models/IPaginationInfo";
import router from "@/router";
export interface IMasterData {
  value: any;
  label: any;
}
export interface ISearchResult {
  id: any;
  name: any;
  email: any;
  phone_number: any;
}


export interface ICancellationReasons {
  value: any;
  label: any;
}

export interface IExport {
  data: string;
}

export interface IDocumentLogData {
  id: any;
  action: any;
  action_by: any;
  module: any;
  created_at: any;
  updated_at: any;
}
export interface MasterInfo {
  masterUrl: MasterUrlInfo;
  userTypes: Array<IMasterData>;
  taskResponse: ITaskResponse;
  taskReasons: IMasterData;
  errors: Array<string>;
  cancellationReasons: ICancellationReasons;

  insurancePaymentType: IMasterData;

  paymentTypes: IMasterData;
  callbackRequestList: IMasterData;
  callbackPetRequestList: IMasterData;
  callbackExpatRequestList: IMasterData;
  paginationObj: IPaginationInfo;
  countryCodeList: IMasterData;
  documentLogs:IDocumentLogData;
}

export interface MasterUrlInfo {
  getUserTypes: string;
  getTaskResponse: string;
  getTaskReasons: string;
  getPaytoMaster: string;
  getSearch: string;
  getInsurancePaymentType: string
  getDocTypes: string;
  getCancellationReason: string;
  getPaymentTypes: string;
  getRefundReason: string;
  getCallbackRequest:string;
  exportCallbackRequest:string;
  exportCallbackPetRequest:string;
  getCountryCode:string;
  addDocumentLog:string;
  getDocumentLog:string;
  getLeadSourceUrl:string;
  getBankDetailsUrl:string;
}

export interface IBankDetails {
  id: number;
  bank: string;
  holder: string;
  account_no: string;
  iban: string;
  swift_code: string;
  insurance: string;
}

export interface PolicyStatusDT {
  value: any;
  label: any;
}

export interface IInsuranceProviders {
  id: number;
  provider_name: string;
  provider_description: string;
  provider_ar_description: string;
  ocr_enabled: boolean;
  insurance_policies_count: number;
  insurance_policies: number;
  created_at: string;
  updated_at: string;
}

export interface IInsurancePolicies {
  id: number
  insurance_provider_id: number
  policy_name: string
  short_name: string
  policy_type: number
  policy_sub_type: number
  created_at: string
  updated_at: string
  status: number
  policy_type_text: string
}


@Module
export default class MasterModule extends VuexModule implements MasterInfo {

  masterUrl = {} as MasterUrlInfo;
  userTypes = [] as Array<IMasterData>;
  taskResponse = {} as ITaskResponse;
  taskReasons = {} as IMasterData;
  docTypes = {} as IMasterData;
  canxDocTypes = {} as IMasterData;
  bankDetails = {} as IBankDetails
  searchResult = {} as ISearchResult;
  searchResultMo = {} as ISearchResult;
  payToMasters = {} as IMasterData;
  insurancePaymentType = {} as IMasterData;
  cancellationReasons = {} as ICancellationReasons;
  refundReasons = {} as ICancellationReasons;
  errors = [];
  paymentTypes  = {} as IMasterData;
  callbackRequestList  = {} as IMasterData;
  callbackPetRequestList  = {} as IMasterData;
  callbackExpatRequestList  = {} as IMasterData;
  export = {} as IExport;
  paginationObj = {
    current_page: 1,
    data: [],
    first_page_url: "",
    from: 1,
    last_page: 1,
    last_page_url: "",
    links: [],
    next_page_url: "",
    path: "",
    per_page: "",
    prev_page_url: "",
    to: 1,
    total: 1,
  } as IPaginationInfo;
  countryCodeList = {} as IMasterData;
  documentLogs = {} as IDocumentLogData;
  openDocumentLogs = false;
  contactUs = [];
  policyStatuses = {} as PolicyStatusDT;
  leadSources = {} as IMasterData;
  insuranceProviders = {} as IInsuranceProviders;
  insurancePoliciesByType = {} as IInsurancePolicies;
  policyStartDateXDaysRestrict = null
  policyStartDateXDaysRestrictUpcoming = null
  carYears = []
  checkTrim = false;
  vat = 0

  @Action
  async[Actions.GET_POLICY_START_DATE_DAYS_RESTRICT]() {
    try {
      const response = await ApiService.post_get('/skye/settings/policy-start-date-days-restrict')
      if(response) {
        const settingValue = parseInt(response.data.data.setting.value)
        const upcomingValue = parseInt(response.data.data.upcoming.value)
        this.context.commit(Mutations.SET_POLICY_START_DATE_DAYS_RESTRICT, settingValue)
        this.context.commit(Mutations.SET_POLICY_START_DATE_DAYS_RESTRICT_UPCOMING, upcomingValue)
      }
    } catch (error) {
      console.log({error})
    }
  }

  @Action
  async[Actions.GET_VAT]() {
    try {
      const response = await ApiService.post_get('/skye/settings/vat')
      if(response) {
        const vat = parseFloat(response.data.data.vat.value)
        this.context.commit(Mutations.SET_VAT, vat)
      }
    } catch (error) {
      console.log({error})
    }
  }

  @Action
  [Actions.GET_USER_TYPES]() {// 
    return new Promise<any>((resolve, reject) => {
      this.masterUrl.getUserTypes = "/skye/master/list-user-types";
      ApiService.post(this.masterUrl.getUserTypes, {})
        .then(({ data }) => {  //
          this.context.commit(Mutations.SET_USER_TYPE, data.data.types);
          resolve(data);
        })
        .catch(({ response }) => { // 
          // this.context.commit(Mutations.SET_MASTER_ERROR, response.data);
          reject(response);
        });
    })
  }
  @Action
  [Actions.GET_TASK_RESPONSE](payload) {// 
    return new Promise<any>((resolve, reject) => {
      this.masterUrl.getTaskResponse = "skye/master/list-task-statuses";
      ApiService.post(this.masterUrl.getTaskResponse, payload)
        .then(({ data }) => {  //
          this.context.commit(Mutations.SET_TASK_RESPONSE, data.data);
          resolve(data);
        })
        .catch(({ response }) => { // 
          // this.context.commit(Mutations.SET_MASTER_ERROR, response.data);
          reject(response);
        });
    })
  }
  @Action
  [Actions.GET_TASK_REASONS](payload) {// 
    return new Promise<any>((resolve, reject) => {
      this.masterUrl.getTaskReasons = "skye/master/task-lead-reasons";
      ApiService.post(this.masterUrl.getTaskReasons, payload)
        .then(({ data }) => {  //
          this.context.commit(Mutations.SET_TASK_REASONS, data.data.reasons);
          resolve(data);
        })
        .catch(({ response }) => { // 
          // this.context.commit(Mutations.SET_MASTER_ERROR, response.data);
          reject(response);
        });
    })
  }
  @Action
  [Actions.GET_DOC_TYPES](payload) {// 

    return new Promise<any>((resolve, reject) => {
      this.masterUrl.getDocTypes = "/skye/master/document-types";
      ApiService.post(this.masterUrl.getDocTypes, payload)
        .then((result) => {  //
          console.log({"result cancel" : result})
          this.context.commit(Mutations.SET_DOC_TYPES, result.data.data.types);
          resolve(result.data);
        })
        .catch(({ response }) => { // 
          // this.context.commit(Mutations.SET_MASTER_ERROR, response.data);
          reject(response);
        });
    })
  }
  
  @Action
  [Actions.GET_CANX_DOC_TYPES](payload) {// 

    return new Promise<any>((resolve, reject) => {
      this.masterUrl.getDocTypes = "/skye/master/document-types";
      ApiService.post(this.masterUrl.getDocTypes, payload)
        .then((result) => {  //
          console.log({"result cancel" : result})
          this.context.commit(Mutations.SET_CANX_DOC_TYPES, result.data.data.types);
          resolve(result.data);
        })
        .catch(({ response }) => { // 
          // this.context.commit(Mutations.SET_MASTER_ERROR, response.data);
          reject(response);
        });
    })
  }
  
  @Action
  [Actions.GET_SEARCH_RESULT](payload) {// 

    return new Promise<any>((resolve, reject) => {
      this.masterUrl.getSearch = "/skye/master/search";
      ApiService.post(this.masterUrl.getSearch, payload)
        .then(({ data }) => {  //
          this.context.commit(Mutations.SET_SEARCH_RESULT, data.data);
          resolve(data);
        })
        .catch(({ response }) => { // 
          // this.context.commit(Mutations.SET_MASTER_ERROR, response.data);
          reject(response);
        });
    })
  }

  @Action
  [Actions.GET_SEARCH_RESULT_MO](payload) {// 

    return new Promise<any>((resolve, reject) => {
      this.masterUrl.getSearch = "/skye/master/search-mo";
      ApiService.post(this.masterUrl.getSearch, payload)
        .then(({ data }) => {  //
          this.context.commit(Mutations.SET_SEARCH_RESULT_MO, data.data);
          resolve(data);
        })
        .catch(({ response }) => { // 
          // this.context.commit(Mutations.SET_MASTER_ERROR, response.data);
          reject(response);
        });
    })
  }


  @Action
  [Actions.GET_CANCELLATION_REASON](payload) {// 

    return new Promise<any>((resolve, reject) => {
      this.masterUrl.getCancellationReason = "/skye/master/cancellation-reasons";
      ApiService.post(this.masterUrl.getCancellationReason, payload)
        .then(({ data }) => {  //
          this.context.commit(Mutations.SET_CANCELLATION_REASON, data.data.reasons);
          resolve(data);
        })
        .catch(({ response }) => { // 
          // this.context.commit(Mutations.SET_MASTER_ERROR, response.data);
          reject(response);
        });
    })
  }
  @Action
  [Actions.GET_REFUND_REASON](payload) {// 

    return new Promise<any>((resolve, reject) => {
      this.masterUrl.getRefundReason = "/skye/master/refund-reasons";
      ApiService.post(this.masterUrl.getRefundReason, payload)
        .then(({ data }) => {  //
          this.context.commit(Mutations.SET_REFUND_REASON, data.data.reasons);
          resolve(data);
        })
        .catch((err) => { // 
          // this.context.commit(Mutations.SET_MASTER_ERROR, response.data);
          // window.location.reload();
          reject(err)
        });
    })
  }
  @Action
  [Actions.GET_PAYTO_MASTERS](payload) {// 

    return new Promise<any>((resolve, reject) => {
      this.masterUrl.getPaytoMaster = "/skye/master/pay-to";
      ApiService.post(this.masterUrl.getPaytoMaster, payload)
        .then(({ data }) => {  //
          this.context.commit(Mutations.SET_PAYTO_MASTERS, data.data.pay_to);
          resolve(data);
        })
        .catch(({ response }) => { // 
          // this.context.commit(Mutations.SET_MASTER_ERROR, response.data);
          reject(response);
        });
    })
  }
  @Action
  [Actions.GET_INSURANCE_PAYMET_TYPE](payload) {// 

    return new Promise<any>((resolve, reject) => {
      this.masterUrl.getInsurancePaymentType = "/skye/master/insurance-payment";
      ApiService.post(this.masterUrl.getInsurancePaymentType, payload)
        .then(({ data }) => {  //
          this.context.commit(Mutations.SET_INSURANCE_PAYMENT_TYPE, data.data.insurance_payment);
          resolve(data);
        })
        .catch(({ response }) => { // 
          // this.context.commit(Mutations.SET_MASTER_ERROR, response.data);
          reject(response);
        });
    })
  }

  @Action
  [Actions.GET_PAYMENT_TYPES]() {// 
    return new Promise<any>((resolve, reject) => {
      this.masterUrl.getPaymentTypes = "/skye/master/payment-types";
      ApiService.post(this.masterUrl.getPaymentTypes, {})
        .then(({ data }) => {  //
          this.context.commit(Mutations.SET_PAYMENT_TYPES, data.data.types);
          resolve(data);
        })
        .catch(({ response }) => { // 
          // this.context.commit(Mutations.SET_MASTER_ERROR, response.data.data);
          reject(response);
        });
    })
  }

  @Action
  [Actions.GET_CALLBACK_REQUEST_LIST](payload) {// 
    return new Promise<any>((resolve, reject) => {
      this.masterUrl.getCallbackRequest = "/skye/inquires/list-callback-request?page=" + payload.page;
      ApiService.post(this.masterUrl.getCallbackRequest, payload)
        .then(({ data }) => {  //
          this.context.commit(Mutations.SET_CALLBACK_REQUEST_LIST, data.data.result);
          resolve(data);
        })
        .catch(({ response }) => { // 
          // this.context.commit(Mutations.SET_MASTER_ERROR, response.data.data);
          reject(response);
        });
    })
  }

  @Action
  [Actions.GET_CALLBACK_PET_REQUEST_LIST](payload) {// 
    return new Promise<any>((resolve, reject) => {
      this.masterUrl.getCallbackRequest = "/skye/inquires/list-callback-pet-request?page=" + payload.page;
      ApiService.post(this.masterUrl.getCallbackRequest, payload)
        .then(({ data }) => {  //
          this.context.commit(Mutations.SET_CALLBACK_PET_REQUEST_LIST, data.data.result);
          resolve(data);
        })
        .catch(({ response }) => { // 
          // this.context.commit(Mutations.SET_MASTER_ERROR, response.data.data);
          reject(response);
        });
    })
  }

  @Action
  [Actions.GET_CALLBACK_EXPAT_REQUEST_LIST](payload) {// 
    return new Promise<any>((resolve, reject) => {
      this.masterUrl.getCallbackRequest = "/skye/inquires/expat-insurance/call-back?page=" + payload.page;
      ApiService.post(this.masterUrl.getCallbackRequest, payload)
        .then(({ data }) => {  //
          this.context.commit(Mutations.SET_CALLBACK_EXPAT_REQUEST_LIST, data.data.result);
          resolve(data);
        })
        .catch(({ response }) => { // 
          // this.context.commit(Mutations.SET_MASTER_ERROR, response.data.data);
          reject(response);
        });
    })
  }


  @Action
  [Actions.GET_CONTACT_US_LIST](payload) {// 
    return new Promise<any>((resolve, reject) => {
      this.masterUrl.getCallbackRequest = "/skye/inquires/contact-us";
      ApiService.post(this.masterUrl.getCallbackRequest, payload)
        .then(({ data }) => {  //
          this.context.commit(Mutations.SET_CONTACT_US, data.data.result);
          resolve(data);
        })
        .catch(({ response }) => { // 
          // this.context.commit(Mutations.SET_MASTER_ERROR, response.data.data);
          reject(response);
        });
    })
  }
  @Action
  [Actions.EXPORT_CALLBACK_REQUEST](payload) {
    return new Promise<any>((resolve, reject) => { // 
      this.masterUrl.exportCallbackRequest = "/skye/inquires/export-callback-request?page=" + payload.page;
      this.context.commit(Mutations.SET_DISABLE_BUTTON, true);
      ApiService.post(this.masterUrl.exportCallbackRequest, payload)
        .then(({ data }) => {
          debugger
          this.context.commit(Mutations.SET_EXPORT_CALLBACK_REQUEST, data.data.result);
          this.context.commit(Mutations.SET_DISABLE_BUTTON, false);
          resolve(data);

        })
        .catch(({ response }) => {// 
          this.context.commit(Mutations.SET_DISABLE_BUTTON, false);
          // this.context.commit(Mutations.SET_MASTER_ERROR, response);
          reject(response);
        });
    });
  }
  @Action
  [Actions.EXPORT_CALLBACK_PET_REQUEST](payload) {
    return new Promise<any>((resolve, reject) => { // 
      this.masterUrl.exportCallbackRequest = "/skye/inquires/export-callback-pet-request?page=" + payload.page;
      this.context.commit(Mutations.SET_DISABLE_BUTTON, true);
      ApiService.post(this.masterUrl.exportCallbackRequest, payload)
        .then(({ data }) => {
          debugger
          this.context.commit(Mutations.SET_EXPORT_CALLBACK_REQUEST, data.data.result);
          this.context.commit(Mutations.SET_DISABLE_BUTTON, false);
          resolve(data);

        })
        .catch(({ response }) => {// 
          this.context.commit(Mutations.SET_DISABLE_BUTTON, false);
          // this.context.commit(Mutations.SET_MASTER_ERROR, response);
          reject(response);
        });
    });
  }

  @Action
  [Actions.EXPORT_CALLBACK_EXPAT_REQUEST](payload) {
    return new Promise<any>((resolve, reject) => { // 
      this.masterUrl.exportCallbackRequest = "/skye/inquires/export-expat-request?page=" + payload.page;
      this.context.commit(Mutations.SET_DISABLE_BUTTON, true);
      ApiService.post(this.masterUrl.exportCallbackRequest, payload)
        .then(({ data }) => {
          debugger
          this.context.commit(Mutations.SET_EXPORT_CALLBACK_REQUEST, data.data.result);
          this.context.commit(Mutations.SET_DISABLE_BUTTON, false);
          resolve(data);

        })
        .catch(({ response }) => {// 
          this.context.commit(Mutations.SET_DISABLE_BUTTON, false);
          // this.context.commit(Mutations.SET_MASTER_ERROR, response);
          reject(response);
        });
    });
  }


  @Action
  [Actions.EXPORT_CONTACT_US_REQUEST](payload) {
    return new Promise<any>((resolve, reject) => { // 
      this.masterUrl.exportCallbackRequest = "/skye/inquires/export-contact-us-request";
      this.context.commit(Mutations.SET_DISABLE_BUTTON, true);
      ApiService.post(this.masterUrl.exportCallbackRequest, payload)
        .then(({ data }) => {
          debugger
          this.context.commit(Mutations.SET_EXPORT_CALLBACK_REQUEST, data.data.result);
          this.context.commit(Mutations.SET_DISABLE_BUTTON, false);
          resolve(data);

        })
        .catch(({ response }) => {// 
          this.context.commit(Mutations.SET_DISABLE_BUTTON, false);
          // this.context.commit(Mutations.SET_MASTER_ERROR, response);
          reject(response);
        });
    });
  }
  @Action
  [Actions.GET_COUNTRY_CODE_LIST]() {// 
    return new Promise<any>((resolve, reject) => {
      this.masterUrl.getCountryCode = "/skye/master/country-codes";
      ApiService.post(this.masterUrl.getCountryCode, {})
        .then(({ data }) => {  //
          this.context.commit(Mutations.SET_COUNTRY_CODE_LIST, data.data.codes);
          resolve(data);
        })
        .catch(({ response }) => { // 
          // router.push('/sign-in')
            // window.localStorage.removeItem('id_token');
            // window.localStorage.removeItem('refresh_token');
            // window.localStorage.removeItem('expires_in');
            // window.localStorage.removeItem('myRoute');
            // window.localStorage.removeItem('user');
          // this.context.commit(Mutations.SET_MASTER_ERROR, response.data.data);
          reject(response);
        });
    })
  }

  @Action
  [Actions.GET_DOCUMENT_LOG_LIST](payload){
      return new Promise<any>((resolve, reject) => {
          this.masterUrl.getDocumentLog = "/skye/documents/list-document-logs";
          ApiService.post(this.masterUrl.getDocumentLog,payload)
          .then(({data}) => { 
            this.context.commit(Mutations.SET_DOCUMENT_LOG_LIST, data.data.logs);
            this.context.commit(Mutations.SET_OPEN_DOCUMENT_LOGS, true);
            resolve(data);
          })
          .catch(({ response }) => { 
              reject(response);
          });
      })
  }
  @Action
  [Actions.ADD_DOCUMENT_LOG](payload){
      return new Promise<any>((resolve, reject) => {
          this.masterUrl.addDocumentLog = "/skye/documents/add-document-log";
          ApiService.post(this.masterUrl.addDocumentLog,payload)
          .then(({data}) => { 
              resolve(data);
          })
          .catch(({ response }) => { 
              reject(response);
          });
      })
  }

  @Action
  [Actions.GET_POLICY_STATUS_LIST](payload) {
    return new Promise<any>((resolve, reject) => {
      const url = "/skye/master/policy-statuses";
      ApiService.post(url, payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_POLICY_STATUS_LIST, data.data);
          resolve(data);
        })
        .catch(({ response }) => {
          // this.context.commit(Mutations.SET_MASTER_ERROR, response.data);
          reject(response);
        });
    })
  }

  @Action
  [Actions.GET_LEAD_SOURCES](payload) {// 

    return new Promise<any>((resolve, reject) => {
      this.masterUrl.getLeadSourceUrl = "/skye/master/lead-sources";
      ApiService.post(this.masterUrl.getLeadSourceUrl, payload)
        .then((result) => {  //
          console.log({"result cancel" : result})
          this.context.commit(Mutations.SET_LEAD_SOURCES, result.data.data);
          resolve(result.data);
        })
        .catch(({ response }) => { // 
          // this.context.commit(Mutations.SET_MASTER_ERROR, response.data);
          reject(response);
        });
    })
  }

  @Action
  [Actions.GET_INSURANCE_PROVIDERS](payload) {
    return new Promise<any>((resolve, reject) => {
      this.masterUrl.getLeadSourceUrl = "/skye/master/insurance-providers";
      ApiService.post(this.masterUrl.getLeadSourceUrl, payload)
        .then((result) => {
          this.context.commit(Mutations.SET_INSURANCE_PROVIDERS, result.data.data.providers);
          resolve(result.data);
        })
        .catch(({ response }) => {
          // this.context.commit(Mutations.SET_MASTER_ERROR, response.data);
          reject(response);
        });
    })
  }
  
  @Action
  [Actions.GET_INSURANCE_POLICY_BY_TYPE_LIST](payload) {
    return new Promise<any>((resolve, reject) => {
      this.masterUrl.getLeadSourceUrl = "/skye/master/insurance-policies-by-type";
      ApiService.post(this.masterUrl.getLeadSourceUrl, payload)
        .then((result) => {
          this.context.commit(Mutations.SET_INSURANCE_POLICY_BY_TYPE_LIST, result.data.data.policies);
          resolve(result.data);
        })
        .catch(({ response }) => {
          // this.context.commit(Mutations.SET_MASTER_ERROR, response.data);
          reject(response);
        });
    })
  }

  @Action
  [Actions.GET_BANK_DETAILS](payload) {
    return new Promise<any>((resolve, reject) => {
      this.masterUrl.getBankDetailsUrl = "/skye/master/get-bank-details";
      ApiService.post(this.masterUrl.getBankDetailsUrl,payload)
        .then((result) => {
          
          this.context.commit(Mutations.SET_BANK_DETAILS, result.data.data.bankDetails);
          resolve(result.data);
        })
        .catch(({ response }) => {
          // this.context.commit(Mutations.SET_MASTER_ERROR, response.data);
          reject(response);
        });
    })
  }
  
  @Action
  async [Actions.GET_CAR_YEARS](payload) {
    try {
      const data = await ApiService.post('/skye/master/car-years', payload)
      this.context.commit(Mutations.SET_CAR_YEARS, data.data.data.car_years)
      return data
    } catch (error) {
      return error
    }
  }

  @Action
  [Actions.GET_CHECK_TRIM](payload) {
    return new Promise<any>((resolve, reject) => {
      ApiService.post('/skye/master/check-trim-level', payload)
        .then((result) => {
          resolve(result.data.data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    })
  }

  // @Action
  // [Actions.GET_CAR_LEAD_STATUS](payload) {
  //   return new Promise<any>((resolve, reject) => {
  //     this.masterUrl.getBankDetailsUrl = "/skye/master/list-car-lead-statuses";
  //     ApiService.post(this.masterUrl.getBankDetailsUrl,payload)
  //       .then((result) => {
          
  //         this.context.commit(Mutations.SET_BANK_DETAILS, result.data.data.bankDetails);
  //         resolve(result.data);
  //       })
  //       .catch(({ response }) => {
  //         // this.context.commit(Mutations.SET_MASTER_ERROR, response.data);
  //         reject(response);
  //       });
  //   })
  // }

  @Action
  [Actions.GET_SPLIT_PAYMENT_TYPES]() {// 
    return new Promise<any>((resolve, reject) => {
      this.masterUrl.getPaymentTypes = "/skye/master/payment-types?type=split_payments";
      ApiService.post(this.masterUrl.getPaymentTypes, {})
        .then(({ data }) => {  //
          this.context.commit(Mutations.SET_PAYMENT_TYPES, data.data.types);
          resolve(data);
        })
        .catch(({ response }) => { // 
          // this.context.commit(Mutations.SET_MASTER_ERROR, response.data.data);
          reject(response);
        });
    })
  }

  
  @Mutation
  [Mutations.SET_BANK_DETAILS](payload) {
    this.bankDetails = payload;
  }
  
  @Mutation
  [Mutations.SET_VAT](payload) {
    this.vat = payload;
  }

  @Mutation
  [Mutations.SET_DOC_TYPES](payload) {
    this.docTypes = payload;
  }
  @Mutation
  [Mutations.SET_CANX_DOC_TYPES](payload) {
    this.canxDocTypes = payload;
  }

  @Mutation
  [Mutations.SET_TASK_ERROR](error) {
    for (const key in error) {
      this.errors = error[key];
    }
  }
  @Mutation
  [Mutations.SET_TASK_RESPONSE](payload) {
    this.taskResponse = payload;
  }

  @Mutation
  [Mutations.SET_USER_TYPE](payload): void {
    this.userTypes = payload;
  }
  @Mutation
  [Mutations.SET_TASK_REASONS](payload): void {
    this.taskReasons = payload;
  }
  
  @Mutation
  [Mutations.SET_SEARCH_RESULT](payload): void {
    this.searchResult = payload;
  }

  @Mutation
  [Mutations.SET_SEARCH_RESULT_MO](payload): void {
    this.searchResultMo = payload;
  }

  @Mutation
  [Mutations.SET_CANCELLATION_REASON](payload): void {
    this.cancellationReasons = payload;
  }
  @Mutation
  [Mutations.SET_REFUND_REASON](payload): void {
    this.refundReasons = payload;
  }
  
  @Mutation
  [Mutations.SET_PAYTO_MASTERS](payload): void {
    this.payToMasters = payload;
  }

  @Mutation
  [Mutations.SET_INSURANCE_PAYMENT_TYPE](payload): void {
    this.insurancePaymentType = payload;
  }

  @Mutation
  [Mutations.SET_PAYMENT_TYPES](payload): void {
    this.paymentTypes = payload;
  }

  @Mutation
  [Mutations.SET_DOCUMENT_LOG_LIST](payload): void {
    this.documentLogs = payload;
  }

  @Mutation
  [Mutations.SET_CONTACT_US](payload): void {
    this.contactUs = payload.data;
    delete payload.data
    this.paginationObj = payload
  }
  
  @Mutation
  [Mutations.SET_CALLBACK_REQUEST_LIST](payload): void {
    this.callbackRequestList = payload.data;
    // setting below vars for pagination
    delete payload.data;
    this.paginationObj = payload;
  }
  @Mutation
  [Mutations.SET_EXPORT_CALLBACK_REQUEST](payload) {
      // 
      this.export = payload;
  }
  
  @Mutation
  [Mutations.SET_CALLBACK_PET_REQUEST_LIST](payload): void {
    this.callbackPetRequestList = payload.data;
    // setting below vars for pagination
    delete payload.data;
    this.paginationObj = payload;
  }

  @Mutation
  [Mutations.SET_CALLBACK_EXPAT_REQUEST_LIST](payload): void {
    this.callbackExpatRequestList = payload.data;
    // setting below vars for pagination
    delete payload.data;
    this.paginationObj = payload;
  }


  @Mutation
  [Mutations.SET_EXPORT_CALLBACK_PET_REQUEST](payload) {
      // 
      this.export = payload;
  }
  @Mutation
  [Mutations.SET_COUNTRY_CODE_LIST](payload): void {
    this.countryCodeList = payload;
  }

  @Mutation
  [Mutations.SET_OPEN_DOCUMENT_LOGS](payload) {
    // 
    this.openDocumentLogs = payload;
  }

  @Mutation
  [Mutations.SET_POLICY_STATUS_LIST](payload): void {
    this.policyStatuses = payload;
  }

  @Mutation
  [Mutations.SET_LEAD_SOURCES](payload): void {
    this.leadSources = payload;
  }

  @Mutation
  [Mutations.SET_INSURANCE_PROVIDERS](payload): void {
    this.insuranceProviders = payload;
  }

  @Mutation
  [Mutations.SET_INSURANCE_POLICY_BY_TYPE_LIST](payload): void {
    console.log(payload);
    this.insurancePoliciesByType = payload;
  }

  @Mutation
  [Mutations.SET_POLICY_START_DATE_DAYS_RESTRICT](payload) {
    this.policyStartDateXDaysRestrict = payload;
  }

  @Mutation
  [Mutations.SET_POLICY_START_DATE_DAYS_RESTRICT_UPCOMING](payload) {
    this.policyStartDateXDaysRestrictUpcoming = payload;
  }

  @Mutation
  [Mutations.SET_CAR_YEARS](payload) {
    this.carYears = payload;
  }

  @Mutation
  [Mutations.SET_CHECK_TRIM](payload) {
    this.checkTrim = payload;
  }
  
  get getUserTaskResponse(): ITaskResponse {
    return this.taskResponse;
  }
  get getUserTaskReasons(): IMasterData {
    return this.taskReasons;
  }
  get getUserTypeList(): Array<IMasterData> {
    return this.userTypes;
  }
  get getMasterDocTypes(): IMasterData {
    return this.docTypes;
  }
  
  get getCanxDocumentTypes(): IMasterData {
    return this.canxDocTypes;
  }
  
  get getPayToList(): IMasterData {
    return this.payToMasters;
  }
  get getInsurancePaymentList(): IMasterData {
    return this.insurancePaymentType;
  }
  get getSearch(): ISearchResult {
    return this.searchResult;
  }
  get getSearchMo(): ISearchResult {
    return this.searchResultMo;
  }
  
  // get getCarLead(): ILeadDetails{
  //     return  this.carLead;
  // }

  get getCancellationReasons(): ICancellationReasons {
    return this.cancellationReasons;
  }
  get getRefundReasons(): ICancellationReasons {
    return this.refundReasons;
  }
  get getPaymentTypeList(): IMasterData {
    return this.paymentTypes;
  }
  get getExportData(): IExport {
    return this.export;
  }
  get getCountryCode(): IMasterData {
    return this.countryCodeList;
  }

  get getOpenDocumentLogs() {
    return this.openDocumentLogs;
  }

  get getDocumentLogs() {
    return this.documentLogs;
  }

  get getContactUs() {
    return this.contactUs;
  }

  get getPolicyStatuses() {
    return this.policyStatuses;
  }

  get getLeadSourcesList() {
    return this.leadSources;
  }

  get getInsuranceProvidersList() {
    return this.insuranceProviders;
  }

  get getInsurancePolicyByType() {
    return this.insurancePoliciesByType;
  }

  get getBankDetailsInformation() {
    return this.bankDetails;
  }

  get getCallBackExportPagination() {
    // callback and quotes
    return this.paginationObj;
  }

  get getPolicyStartDateXDaysRestrict() {
    return this.policyStartDateXDaysRestrict
  }

  get getPolicyStartDateXDaysRestrictUpcoming() {
    return this.policyStartDateXDaysRestrictUpcoming
  }

  get getCarYears() {
    return this.carYears
  }

  get getCheckTrimLevel() {
    return this.checkTrim;
  }

  get getVat() {
    return this.vat;
  }
}
