import store from "@/store";
import router from "@/router";
import { Actions } from "@/store/enums/StoreEnums";
import { App, computed } from "vue";
// import Swal from "sweetalert2/dist/sweetalert2.js";
import { notif } from '@/store/stateless/store';
// import $ from 'jquery'; 
/**
 * @description service to call HTTP request via Axios
 */
class UserService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    UserService.vueInstance = app;
  }


  public static getUserList(payload, index = 1) {

    payload.index = index;
    return new Promise<void>((resolve, reject) => {
      store
        .dispatch(Actions.GET_USER_LIST, payload)
        .then((data) => {

          resolve(data);

        })
        .catch((error) => {
          console.log(error);
          reject(error);
        })
    });

  }

  public static getRoles() {
    store.dispatch(Actions.GET_ROLES)
      .then((data) => { return data; })
      .catch((error) => { console.log(error); })

  }

  public static async getUnderWriters(payload = {}) {
    // return new Promise<any>((resolve, reject) => {
    // store.dispatch(Actions.GET_UNDERWRITERS_LIST)
    //   .then((data) => { resolve( data); })
    //   .catch((error) => { console.log(error); });
    // });

    return await store.dispatch(Actions.GET_UNDERWRITERS_LIST, payload);

  }
  public static getDomSalesAgents(payload) {
    return new Promise<void>((resolve, reject) => {
    store.dispatch(Actions.GET_DOM_SALES_AGENTS, payload)
      .then((data) => { resolve( data); })
      .catch((error) => { console.log(error); })
    });
  }
  public static getDomSalesAgentsManagerTeamLeader() {
    return new Promise<any>((resolve, reject) => {
    store.dispatch(Actions.GET_DOM_SALES_AGENT_MANAGER_TEAMLEADER, {
      type: 'Car',
      isArchived: true,
      isActive: true
    })
      .then((data) => { 
        console.log(data)
        resolve( data);
       })
      .catch((error) => { 
          console.log(`error caused by continues reload : ${error.toString()}`);
        })
    });
  }
  public static getTeamLeaders() {
    return new Promise<void>((resolve, reject) => {
      store
        .dispatch(Actions.GET_TEAM_LEADERS_LIST)
        .then((data) => {

          resolve(data);

        })
        .catch((error) => {
          console.log(error);
          reject(error);
        })
    });
  }
  public static addUser(data) {
    return new Promise((res, rej) => {
      store.dispatch(Actions.ADD_USER, data)
      .then((result) => {
        notif.simple('User', 'success', 'User has been added successfully!');
        router.push({ name: "user-list" });
        res(result)
      })
      .catch((err) => {
        rej(err)
      })
    })
  }


  public static async fetchUser(payload) {// 
   return await store.dispatch(Actions.FETCH_USER, payload)
  }

  public static editUser(data) {
    return new Promise((res, rej) => {
      store.dispatch(Actions.EDIT_USER, data)
      .then((result) => {
        notif.simple('User', 'success', 'User has been updated successfully !')
        router.push({ name: "user-list" });
        res(result)
      })
      .catch((error) => {
        rej(error)
      })
    })
  }

  public static checkToken(data) {
    store.dispatch(Actions.CHECK_TOKEN, data) 
  }

  public static getDomSkyeUsers() {
    return new Promise<void>((resolve, reject) => {
    store.dispatch(Actions.GET_DOM_SKYE_USERS)
      .then((data) => { resolve( data); })
      .catch((error) => { console.log(error); })
    });
  }

  public static getAgentBySetting(data) {
    return new Promise<any>((resolve, reject) => {
    store.dispatch(Actions.GET_AGENT_BY_SETTING,data)
      .then((data) => { resolve( data); })
      // .catch((error) => { console.log(error); })
    });
  }

  public static getActiveDomSalesAgents(payload) {
    return new Promise<void>((resolve, reject) => {
    store.dispatch(Actions.GET_ACTIVE_DOM_SALES_AGENTS, payload)
      .then((data) => { resolve( data); })
      .catch((error) => { console.log(error); })
    });
  }

  public static getActiveUnderwriters() {
    return store.dispatch(Actions.GET_ACTIVE_UNDERWRITERS)
  }


}
export default UserService;