import router from "@/router";
import store from "@/store";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { App, computed } from "vue";
// import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { notif } from '@/store/stateless/store';
// import $ from 'jquery'; 
/**
 * @description service to call HTTP request via Axios
 */
class QuotesService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    QuotesService.vueInstance = app;
  }
  public static getManufacturers(data) {
    return new Promise((resolve, reject) => {
      store.dispatch(Actions.GET_MANUFACTURERS, data)
        .then((data) => { resolve(data); })
        .catch((error) => { reject(error); });
    });
  }
  public static getBikeManufacturers(data) {
    return new Promise((resolve, reject) => {
      store.dispatch(Actions.GET_BIKE_MANUFACTURERS, data)
        .then((data) => { resolve(data); })
        .catch((error) => { reject(error); });
    });
  }

  public static getModels(data) {
    return new Promise((resolve, reject) => {
      store.dispatch(Actions.GET_MODELS, data)
        .then((data) => { console.log(data); resolve(data); })
        .catch((error) => { console.log(error); });
    });
  }
  public static getTrims(data) {
    return new Promise((resolve, reject) => {
      store.dispatch(Actions.GET_TRIMS, data)
        .then((data) => { console.log(data); resolve(data); })
        .catch((error) => { console.log(error); })
    });
  }
  public static getCountries() {
    return new Promise((resolve, reject) => {
      store.dispatch(Actions.GET_COUNTRIES)
        .then((data) => { console.log(data); resolve(data); })
        .catch((error) => { console.log(error); })
    });
  }
  public static addQuote(data) {
    return new Promise((resolve, reject) => {
      store.dispatch(Actions.ADD_QUOTES, data)
        .then((data) => {
          // notif.simple("Customer", "success", "save successfully!");
          resolve(data);
          setTimeout(() => {
            const url = router.resolve({ name: "customer-details", params: { customerId: data.data.lead_driver_details.customer_id } });
            window.location.href = url.href
          }, 2000);
        })
        .catch((error) => {
          // 

          reject(error);
        })
    });
  }
  public static editQuote(payload, carToBike) {
    return new Promise((resolve, reject) => {
      store.dispatch(Actions.EDIT_QUOTES, payload)
        .then((data) => {
          if(carToBike) {
            const url = router.resolve({ name: "edit-bike-quote", params: { driverDetailsId: payload.driver_details_id } });
            window.location.href = url.href
          } else {
            const url = router.resolve({ name: "customer-details", params: { customerId: payload.customer_id } });
            window.location.href = url.href
          }
          resolve(data);
        })
        .catch((error) => {
          reject(error)
        })
    });
  }
  public static getDriverDetailsList(payload) {
    return new Promise((res, rej) => {
      store.dispatch(Actions.GET_DRIVER_DETAILS_LIST, payload)
      .then((data) => {
        res(data.data.quotes)
      })
      .catch((error) => {
        rej(error)
      })
    })
  }
  public static sendQuote(payload) {
    store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");
    store.dispatch(Actions.SEND_QUOTE, payload)
      .then((data) => {
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");

        // Swal.fire({
        //   text: data.message,
        //   icon: "success",
        //   buttonsStyling: false,
        //   confirmButtonText: "Okay!",
        //   customClass: {
        //     confirmButton: "btn fw-bold btn-light-success",
        //   },
        // });
        notif.simple('Quotes Generated', 'success', 'Quotation has been generated successfully.');
      })
      .catch((error) => {
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
      })
  }
  public static fetchQuote(payload) {// 
    return new Promise((resolve, reject) => {
      store.dispatch(Actions.FETCH_QUOTE, payload)
        .then((data) => {// 
          const payload = {
            "manufacturer_id": data.data.quote.lead.manufacturer_id,
            "year": data.data.quote.lead.car_year,
            "is_vintage": data.data.quote.lead.is_vintage
          };
          const trimPayload = {
            "model_id": data.data.quote.lead.model_id,
            "year": data.data.quote.lead.car_year,
            "is_vintage": data.data.quote.lead.is_vintage
          };// 
          this.getModels(payload);
          this.getTrims(trimPayload);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
          // 
        })
    });

  }
  public static isLeadRenewalPending(payload) {// 
    return new Promise((resolve, reject) => {
      store.dispatch(Actions.IS_LEAD_RENEWAL_PENDING, payload)
        .then((data) => {// 
        
          resolve(data);
        })
        .catch((error) => {
          reject(error);
          // 
        })
    });

  }

  public static resetEditObject() {
    store.commit(Mutations.SET_EDIT_QUOTE, {});
  }

  public static getEstimatedValue(data) {
    return new Promise((resolve, reject) => {
      store.dispatch(Actions.GET_ESTIMATED_VALUE, data)
        .then((data) => {// 
          console.log('getEstimatedValue', data.data.value.old_max);
          // return data.old_max;
          resolve(data);

        })
        .catch((error) => { console.log(error); reject(error); })
    });
  }
  public static fetchCarLead(data) {
    return new Promise((resolve, reject) => {
      store.dispatch(Actions.FETCH_CAR_LEAD, data)
        .then((data) => {
          console.log(data);
          const payload = {
            "manufacturer_id": data.data.lead.manufacturer_id
          };
          const trimPayload = {
            "model_id": data.data.lead.model_id,
            "year": data.data.lead.car_year
          };// 
          this.getModels(payload);
          this.getTrims(trimPayload);

          const params = {
            "manufacturer_id": data.data.lead.manufacturer_id,
            "model_id": data.data.lead.model_id,
            "trim_level_id": data.data.lead.trim_level_id,
            "car_year": data.data.lead.car_year
          };
          this.getEstimatedValue(params);
          resolve(data);
        })
        .catch((error) => { console.log(error); reject(error); })
    });
  }
  public static compareCompQuote(data) {
    store.dispatch(Actions.GET_LEAD_COMP_QUOTES_LIST, data)
      .then((data) => { console.log(data); })
      .catch((error) => { console.log(error); })
  }
  public static compareTplQuote(data) {
    store.dispatch(Actions.GET_LEAD_TPL_QUOTES_LIST, data)
      .then((data) => { console.log(data); })
      .catch((error) => { console.log(error); })
  }

  public static getManualQuotes(data) {// 
    store
      .dispatch(Actions.GET_MANUAL_QUOTES, data)
      .then((data) => { console.log(data); })
      .catch((error) => { console.log(error); })
  }

  public static getInsuranceCompanies(data) {// 
    return new Promise((resolve, reject) => {
      store
        .dispatch(Actions.GET_INSURANCE_COMPANIES, data)
        .then((data) => { resolve(data); })
        .catch((error) => { reject(error); })
    });
  }

  public static sendManualQuotes(payload) {
    store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");
    store.dispatch(Actions.SEND_MANUAL_QUOTE, payload)
      .then((data) => {
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
        // 
        // Swal.fire({
        //   text: data.message,
        //   icon: "success",
        //   buttonsStyling: false,
        //   confirmButtonText: "Okay!",
        //   customClass: {
        //     confirmButton: "btn fw-bold btn-light-success",
        //   },
        // });
        notif.simple('Quotes Generated', 'success', 'Quotation has been generated successfully.');
      })
      .catch((error) => {
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
        console.log(error);
      })
  }

  public static addManualQuotes(data) {
    return new Promise((resolve, reject) => {
      store.dispatch(Actions.ADD_MANUAL_QUOTES, data)
        .then((data) => {


          if (data.success) {
           
            // Swal.fire({
            //   text: data.message,
            //   icon: "success",
            //   buttonsStyling: false,
            //   confirmButtonText: "Okay!",
            //   customClass: {
            //     confirmButton: "btn fw-bold btn-light-success",
            //   },
            // });
            notif.simple('Quotes Generated', 'success', 'Quotation has been generated successfully.');
            window.open(data.data.quotation.file_path, '_blank');

            setTimeout(() => {
              const url = router.resolve({ name: "customer-details", params: { customerId: data.data.quotation.customer_id } });
              window.location.href = url.href
            }, 1500);
          }

          resolve(data);
        })
        .catch((error) => {
          // 
          reject(error);
        })
    });
  }

  public static downloadManualQuotes(payload) {
    payload.responseType = "arraybuffer";
    store.dispatch(Actions.DOWNLOAD_MANUAL_QUOTES, payload)
      .then((response) => {
        // 
        console.log(response)
        const blob = new Blob([response.data], {
          type: 'application/pdf'
        })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'test.pdf'
        link.click()

      })
      .catch((error) => {
        console.log(error);
      })
  }

  public static resetValues() {
    store.commit(Mutations.SET_MANUFACTURERS, []);
    store.commit(Mutations.SET_MODELS, []);
    store.commit(Mutations.SET_TRIMS, []);
  }
  public static resetModelValues(){
    store.commit(Mutations.SET_MODELS, []);
    store.commit(Mutations.SET_TRIMS, []);
  }
  public static resetTrimValues(){
    store.commit(Mutations.SET_TRIMS, []);
  }

  public static callingQuoteRequestListing(payload) {
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.GET_QUOTE_REQUEST_LISTING, payload)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        })
    });
  }

  public static callingQuoteRequestDocs(payload) {
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.GET_QUOTE_REQUEST_DOCS, payload)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        })
    });
  }

  public static updateQRDocType(payload) {
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.GET_UPDATE_QUOTE_REQUEST_DOCUMENT, payload)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        })
    });
  }

  public static approveQuoteRequest(payload) {
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.APPROVE_QUOTE_REQUEST, payload)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        })
    });
  }

  public static async updateQuoteRequestDocNotes(payload) {
      await store.dispatch(Actions.UPDATE_QUOTE_DOCS_NOTES, payload);
  }

  public static sendQuoteRequest(payload) {
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.SEND_QUOTE_REQUEST, payload)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        })
    });
  }

  public static deleteQuoteRequestDocument(payload) {
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.DELETE_QR_DOCUMENT, payload)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        })
    });
  }

  public static requestingPaymentLink(payload) {
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.REQUEST_PAYMENT_LINK, payload)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        })
    });
  }

  public static sendingPaymentLink(payload) {
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.SEND_PAYMENT_LINK, payload)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        })
    });
  }

  public static async deleteQuoteRequestById(payload) {
    return await store.dispatch(Actions.DELETE_QUOTE_REQUEST, payload);
  }

  public static async fetchMasterFees() {
    return await store.dispatch(Actions.FETCH_MASTER_FEES);
  }

}

export default QuotesService;
