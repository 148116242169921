
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Mutation, Action, Module, VuexModule } from "vuex-module-decorators";
import ApiService from "@/core/services/ApiService";
import IPaginationInfo from "@/models/IPaginationInfo";
import IPolicyDetails from "@/models/car/IPolicyDetails";
import { IPolicyList } from "@/models/car/IPolicyList";
import IPolicyEmailList from "@/models/car/IPolicyEmailList";
import IPolicyDocuments from "@/models/car/IPolicyDocuments";
import { NumberLocale } from "yup/lib/locale";
// import Invoice from "@/models/car/Invoice";
import axios from "axios";
import { notif } from '@/store/stateless/store';
import router from "@/router";
import store from "@/store";

 
export interface IExport {
  data: string;
}
export interface PolicyInfo {
  policyUrl: PolicyUrlInfo;
  policyList: IPolicyList;
  leadPolicyList: Array<IPolicyList>;
  paginationObj: IPaginationInfo;
  policyListCount: IPolicyCount;
  policyDetails: IPolicyDetails;
  policyDocuments: Array<IPolicyDocuments>;
  allPolicyDocuments:Array<IPolicyDocuments>;
  leadDocuments: IPolicyDocuments;
  policyCancellationDocuments: Array<IPolicyTempDocuments>;
  policyEmailList: IPolicyEmailList;
  policyCancelledList: IPolicyList;
  PreviousPolicyStatus: IPreviousPolicySatus;
}
export interface PolicyUrlInfo {
  getPolicyList: string;
  getPolicyListCount: string;
  getLeadPolicyList: string;
  getPolicyDetails: string;
  upoadPolicy: string;
  deleteDocument: string;
  getPolicyDocumentsUrl: string;
  getAllPolicyDocumentsUrl: string;
  listTempPolicyDocumentsUrl: string;
  deleteTempPolicyDocumentsUrl: string;
  updateTempPolicyDocumentsUrl: string;
  saveAsDraft: string;
  getPolicyMailListUrl: string;
  sendCustomerEmail: string;
  sendBrokerEmail: string;
  exportPolicyAssigned: string;
  transferSale: string;
  transferUnderwriter: string;
  cancelPolicyRequest: string;
  editCancelPolicy: string;
  cancelPolicyDocuments: string;
  underwritterCancelPolicy: string;
  refundPolicyRequest: string;
  getRefundPolicyList: string;
  getRefundPolicyListCount: string;
  refundPolicyApproval: string;
  getCancelledPolicyList: string;
  getPreviousPolicySatus: string;
  getPreviousPolicyDocuments: string;
  reUploadTempDocument: string;
  downloadZipDocuments: string;
  updatePolicyDataUrl:string;
}
export interface IPolicyCount {
  all: number;
  today: number;
  yesterday: number;
  last_30_days: number;
  older_than_30_days: number;
}


export interface IPolicyTempDocuments {
  id: number;
  lead_id: number;
  customer_id: number;
  policy_id: number;
  created_by_skye_user: number;
  created_by_customer?: any;
  title?: any;
  filename: string;
  created_at: string;
  updated_at?: any;
  deleted_at?: any;
  file_path: string;
  file_extension: string;
  file_public_path: string;
}

export interface IPreviousPolicySatus {
  "policy_id": any,
  "status": true
}

export interface ModalValue {
  "invoice_id": number,
  "status": number
}


@Module
export default class PolicyModule extends VuexModule implements PolicyInfo {
  policyUrl = {} as PolicyUrlInfo;
  policyList = {} as IPolicyList;
  paginationObj = {
    current_page: 1,
    data: [],
    first_page_url: "",
    from: 1,
    last_page: 1,
    last_page_url: "",
    links: [],
    next_page_url: "",
    path: "",
    per_page: "",
    prev_page_url: "",
    to: 1,
    total: 1,
  } as IPaginationInfo;
  leadPolicyList = [];
  policyListCount = {} as IPolicyCount;
  policyDetails = {} as IPolicyDetails;
  policyDocuments = [];
  allPolicyDocuments = [];
  leadDocuments = {} as IPolicyDocuments;
  policyTempDocuments = [];
  policyTempCancelDocuments = [];
  policyCancellationDocuments = [];
  policyEmailList = {} as IPolicyEmailList;
  export = {} as IExport;
  policyCancelledList = {} as IPolicyList;
  PreviousPolicyStatus = {} as IPreviousPolicySatus;
  PreviousPolicyDocuments = {} as IPolicyDocuments;
  errors: any;
  errorDetails = [];
  openModal = false;
  openModalValue = {} as ModalValue;
  isLoading = false;
  policyIsActive = false;
  documentError = "";
  policyLeadLogs = [];
  debitValue = 0
  loadingTempDocument = true
  paLoading = true;
  editCancellationLoading = true;
  refundModal = false;
  discrepancyModal = false;
  discrepancyModalText = null;
  editPolicyRefundError = null;
  isDiscrepancyAtBackEnd = null;
  policyDocumentLoading = true;
  childInvoice = [];
  cancelModal = false;
  extensionOpen = false;
  extensions = [];
  category_refund = 1;
  refundInvoiceId = 0;
  isPolicyView = false;

  // isApiCalled = false;
  @Action
  [Actions.GET_SETTING_VALUE](payload) {
    

    return new Promise<any>((res, rej) => {
      ApiService.post('skye/policies/get-policy-amount-accepted', payload)
      .then((result) => {
        this.context.commit(Mutations.SET_DEBIT_VALUE, result.data.data.value);
      })
      .catch((err) => {
        // notif.simple('Error', 'error', 'Run Settings seeder')
      })
    })
  }

  @Action
  [Actions.GET_POLICIES_LIST](payload) { // 
    this.context.commit(Mutations.SET_PA_LOADING, payload.reload);
    // this.isApiCalled = true;
    return new Promise<any>((resolve, reject) => { // 

      if(payload.reload) this.context.commit(Mutations.SET_POLICY_LIST_DOM, false);
      this.policyUrl.getPolicyList = `skye/policies/list-policies-v2?page=${payload.index}`;
      ApiService.post(this.policyUrl.getPolicyList, payload)
        .then(({ data }) => {// 
          this.context.commit(Mutations.SET_POLICY_LIST_DOM, true);
          if(payload.reload) this.context.commit(Mutations.SET_PA_LOADING, false);
          this.context.commit(Mutations.SET_POLICY_LIST, data.data.policies);
          // this.isApiCalled = false;
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_PA_LOADING, false);
          this.context.commit(Mutations.SET_POLICY_LIST_DOM, true);
          this.context.commit(Mutations.SET_POLICY_ERROR, response.data);
          // this.isApiCalled= false;
          reject(response);
        });
    });
  }
  @Action
  [Actions.GET_REFUND_POLICIES_LIST](payload) { // 
    // this.isApiCalled = true;
    this.context.commit(Mutations.SET_PA_LOADING, payload.reload);
    return new Promise<any>((resolve, reject) => { // 

      if(payload.reload) this.context.commit(Mutations.SET_POLICY_LIST_DOM, false);
      this.policyUrl.getRefundPolicyList = `skye/policies/list-refund-policies?page=${payload.index}`;
      ApiService.post(this.policyUrl.getRefundPolicyList, payload)
        .then(({ data }) => {// 
          console.log(data.data.policies);
          this.context.commit(Mutations.SET_POLICY_LIST_DOM, true);
          if(payload.reload) this.context.commit(Mutations.SET_PA_LOADING, false);
          this.context.commit(Mutations.SET_POLICY_LIST, data.data.policies);
          // this.isApiCalled = false;
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_PA_LOADING, false);
          this.context.commit(Mutations.SET_POLICY_LIST_DOM, true);
          this.context.commit(Mutations.SET_POLICY_ERROR, response.data);
          // this.isApiCalled= false;
          reject(response);
        });
    });
  }
  @Action
  [Actions.GET_REFUND_POLICIES_LIST_COUNT](payload) { // 
    // this.isApiCalled= true;
    return new Promise<any>((resolve, reject) => {

      this.policyUrl.getRefundPolicyListCount = "skye/policies/list-refund-policies-count";
      ApiService.post(this.policyUrl.getRefundPolicyListCount, payload)
        .then(({ data }) => {// 
          this.context.commit(Mutations.SET_POLICY_LIST_COUNT, data.data.count);
          // this.isApiCalled= false;
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_POLICY_ERROR, response);
          // this.isApiCalled= false;
          reject(response);
        });
    });
  }
  @Action
  [Actions.GET_POLICIES_LIST_COUNT](payload) { // 
    // this.isApiCalled= true;
    return new Promise<any>((resolve, reject) => {

      this.policyUrl.getPolicyListCount = "skye/policies/list-policies-count";
      ApiService.post(this.policyUrl.getPolicyListCount, payload)
        .then(({ data }) => {// 
          this.context.commit(Mutations.SET_POLICY_LIST_COUNT, data.data.count);
          // this.isApiCalled= false;
          resolve(data);
        })
        .catch(({ response }) => {
          // this.context.commit(Mutations.SET_POLICY_ERROR, response);
          // this.isApiCalled= false;
          reject(response);
        });
    });
  }
  @Action
  [Actions.GET_LEAD_POLICIES_LIST](payload) { // 
    // this.isApiCalled= true;
    return new Promise<any>((resolve, reject) => {
      
      this.policyUrl.getLeadPolicyList = "skye/policies/list-car-customer-policies";
      ApiService.post(this.policyUrl.getLeadPolicyList, payload)
        .then(({ data }) => {// 
          this.context.commit(Mutations.SET_LEAD_POLICY_LIST, data.data.policies);
          this.context.commit(Mutations.SET_POLICY_ACTIVE);
          // this.isApiCalled= false;
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_POLICY_ERROR, response);
          // this.isApiCalled= false;
          reject(response);
        });
    });
  }

  @Action
  [Actions.GET_POLICY_DETAILS](payload) { // 
    // this.isApiCalled= true;
    return new Promise<any>((resolve, reject) => {
      this.context.commit(Mutations.SET_POLICY_DOCUMENT_DOM_STATUS, false);
      this.context.commit(Mutations.SET_POLICY_DOM_DETAILS, false);
      this.policyUrl.getPolicyDetails = "skye/policies/get-car-customer-policy-details";
      ApiService.post(this.policyUrl.getPolicyDetails, payload)
        .then(({ data }) => {// 
          this.context.commit(Mutations.SET_POLICY_DETAILS, data.data.policy);
          this.context.commit(Mutations.SET_POLICY_DOCUMENT_DOM_STATUS, true);
          this.context.commit(Mutations.SET_POLICY_DOM_DETAILS, true);
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_POLICY_ERROR, response);
          // this.isApiCalled= false;
          reject(response);
        });
    });
  }
  @Action
  [Actions.DELETE_DOCUMENT](payload) { // 
    // this.isApiCalled= true;
    return new Promise<any>((resolve, reject) => {

      this.policyUrl.deleteDocument = "skye/policies/delete-policy-document";
      ApiService.post(this.policyUrl.deleteDocument, payload)
        .then(({ data }) => {// 
          this.context.commit(Mutations.SET_POLICY_DETAILS, data.data.policy);

          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_POLICY_ERROR, response);

          reject(response);
        });
    });
  }
  @Action
  [Actions.UPLOAD_POLICY](payload) {
    this.context.commit(Mutations.SET_POLICY_SAVE_BUTTON_DISABLED, true);
    this.context.commit(Mutations.SET_IS_DISCREPANCY_ERROR, false);

    this.policyUrl.upoadPolicy = "skye/policies/add-car-customer-policy";
    return new Promise<any>((resolve, reject) => {
      console.log(payload.is_discrepancy)
      ApiService.post(this.policyUrl.upoadPolicy, payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_POLICY_SAVE_BUTTON_DISABLED, false);
          resolve(data);
        })
        .catch((error) => {
          let data = '';
          console.log('error.response.data',error.response.data.data)
          Object.entries(error.response.data.data).forEach(element => {
              if(element[1] == "Please select Document Type")
              {
                data = '<p>' + element[1] + '</p>'
              }
              else if (element[1] != false){
                data += '<p>' + element[1] + '</p>'
              }
              
          });
          
          data += '';

          const errors = Array.isArray(error.response.data.data) ? `<p">${error.response.data.message}</p>`  : data;
          if(!error.response.data.data.discrepancy_validation && !payload.is_discrepancy) {
            const respo = ['You cannot upload the policy because sale price is less than debit note', 'Incorrect Debit Amount entered']
            if(!respo.includes(error.response.data.message)) {
              // console.log(error.response.data.message)
              notif.simple('Please Note', 'warning', errors, 0)
            }
          }

          if(!error.response.data.data.discrepancy_validation && payload.is_discrepancy) {
            // notif.simple('Please Note', 'warning', error.response.data.message, 0)
            setTimeout(() => {
              router.push({ name: 'policy-assigned' })
            }, 2000);
          }

          if(!error.response.data.data.discrepancy_validation && error.response.data.message === 'Incorrect Debit Amount entered') {
            this.context.commit(Mutations.SET_IS_DISCREPANCY_ERROR, true);
          }
          
          this.context.commit(Mutations.SET__DISCREPANCY_MODAL, error.response.data.data.discrepancy_validation);
          this.context.commit(Mutations.SET__DISCREPANCY_MODAL_TEXT, error.response.data.message);
          this.context.commit(Mutations.SET_POLICY_SAVE_BUTTON_DISABLED, false);
          this.context.commit(Mutations.SET_POLICY_ERROR, error.response);
          // console.log(response)
          reject(error);
        });
    });

  }
  @Action
  [Actions.SAVE_AS_DRAFT](payload) {
    this.policyUrl.saveAsDraft = "skye/policies/save-draft";
    this.context.commit(Mutations.SET_POLICY_DRAFT_SAVE_BUTTON_DISABLED, true);

    return new Promise<any>((resolve, reject) => {
      ApiService.post(this.policyUrl.saveAsDraft, payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_POLICY_DRAFT_SAVE_BUTTON_DISABLED, false);
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_POLICY_DRAFT_SAVE_BUTTON_DISABLED, false);
          this.context.commit(Mutations.SET_POLICY_ERROR, response);
          reject();
        });
    });

  }
  @Action
  [Actions.DOWNLOAD_ZIP_DOCUMENTS](payload) {
    this.policyUrl.downloadZipDocuments = "skye/master/generate-zip";
    this.context.commit(Mutations.SET_POLICY_DRAFT_SAVE_BUTTON_DISABLED, true);
    return new Promise<any>((resolve, reject) => {
      ApiService.post(this.policyUrl.downloadZipDocuments, payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_POLICY_DRAFT_SAVE_BUTTON_DISABLED, false);
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_POLICY_DRAFT_SAVE_BUTTON_DISABLED, false);
          this.context.commit(Mutations.SET_POLICY_ERROR, response);
          reject();
        });
    });

  }
  @Action
  [Actions.GET_POLICY_DOCUMENTS](payload) {
    this.context.commit(Mutations.SET_POLICY_DOCUMENT_LOADING, true);
    this.policyUrl.getPolicyDocumentsUrl = "skye/policies/list-lead-policy-documents";
    return new Promise<any>((resolve, reject) => {
      this.context.commit(Mutations.SET_POLICY_DOCUMENT_DOM_STATUS, false);
      this.context.commit(Mutations.SET_POLICY_DOCUMENT_MODAL_DOM_STATUS, false);
      ApiService.post(this.policyUrl.getPolicyDocumentsUrl, payload)
        .then(({ data }) => {// 

          
          // this.isApiCalled= false;
          this.context.commit(Mutations.SET_POLICY_DOCUMENT_DOM_STATUS, true);
          this.context.commit(Mutations.SET_POLICY_DOCUMENT_MODAL_DOM_STATUS, true);
          this.context.commit(Mutations.SET_POLICY_DOCUMENT_LOADING, false);
          this.context.commit(Mutations.SET_POLICY_DOCUMENTS, data.data.documents);
          resolve(data);
        })
        .catch(({ response }) => {

          this.context.commit(Mutations.SET_POLICY_ERROR, response);
          this.context.commit(Mutations.SET_POLICY_DOCUMENT_DOM_STATUS, true);
          this.context.commit(Mutations.SET_POLICY_DOCUMENT_MODAL_DOM_STATUS, true);
          // this.isApiCalled= false;
          this.context.commit(Mutations.SET_POLICY_DOCUMENT_LOADING, false);
          reject(response);
        });
    });

  }

  @Action
  async [Actions.GET_VIEW_ALL_DOCUMENTS](payload) {
    this.context.commit(Mutations.SET_OPEN_VIEW_ALL_DOCUMENTS, true)
    this.context.commit(Mutations.SET_LOADING_VIEW_ALL_DOCUMENTS, true)
    this.context.commit(Mutations.SET_DATA_VIEW_ALL_DOCUMENTS, [])
    try {
      const result = await ApiService.post('skye/policies/list-temp-and-policy-document', payload)
      this.context.commit(Mutations.SET_DATA_VIEW_ALL_DOCUMENTS, result.data.data.documents)
      this.context.commit(Mutations.SET_LOADING_VIEW_ALL_DOCUMENTS, false)
      return result
    } catch (error) {
      this.context.commit(Mutations.SET_OPEN_VIEW_ALL_DOCUMENTS, true)
      this.context.commit(Mutations.SET_LOADING_VIEW_ALL_DOCUMENTS, true)
      this.context.commit(Mutations.SET_DATA_VIEW_ALL_DOCUMENTS, [])
      return error
    }
  }

  @Action
  [Actions.GET_ALL_DOCUMENTS](payload) {
    this.context.commit(Mutations.SET_POLICY_DOCUMENT_LOADING, true);
    this.policyUrl.getAllPolicyDocumentsUrl = "skye/policies/list-temp-and-policy-document";
    return new Promise<any>((resolve, reject) => {
      this.context.commit(Mutations.SET_POLICY_DOCUMENT_DOM_STATUS, false);
      this.context.commit(Mutations.SET_POLICY_DOCUMENT_MODAL_DOM_STATUS, false);
      ApiService.post(this.policyUrl.getAllPolicyDocumentsUrl, payload)
        .then(({ data }) => {// 

          // this.isApiCalled= false;
          this.context.commit(Mutations.SET_POLICY_DOCUMENT_DOM_STATUS, true);
          this.context.commit(Mutations.SET_POLICY_DOCUMENT_MODAL_DOM_STATUS, true);
          this.context.commit(Mutations.SET_POLICY_DOCUMENT_LOADING, false);
          this.context.commit(Mutations.SET_ALL_POLICY_DOCUMENTS, data.data.documents);
          resolve(data);
        })
        .catch(({ response }) => {

          this.context.commit(Mutations.SET_POLICY_ERROR, response);
          this.context.commit(Mutations.SET_POLICY_DOCUMENT_DOM_STATUS, true);
          this.context.commit(Mutations.SET_POLICY_DOCUMENT_MODAL_DOM_STATUS, true);
          // this.isApiCalled= false;
          this.context.commit(Mutations.SET_POLICY_DOCUMENT_LOADING, false);
          reject(response);
        });
    });

  }
  @Action
  [Actions.RESET_POLICY_DOCUMET_STATUS]() {
    this.context.commit(Mutations.SET_POLICY_DOCUMENT_MODAL_DOM_STATUS, false);
    this.context.commit(Mutations.SET_TEMP_DOCUMENTS, []);
    this.context.commit(Mutations.SET_ALL_POLICY_DOCUMENTS,[]);
    this.context.commit(Mutations.SET_POLICY_DOCUMENT_LOADING,true);
  }

  @Action
  [Actions.GET_LEAD_DOCUMENTS](payload) { // here
    this.context.commit(Mutations.SET_POLICY_DOCUMENT_LOADING, true);
    this.policyUrl.getPolicyDocumentsUrl = "skye/policies/list-lead-policy-documents";
    return new Promise<any>((resolve, reject) => {
      this.context.commit(Mutations.SET_POLICY_DOCUMENT_DOM_STATUS, false);
      ApiService.post(this.policyUrl.getPolicyDocumentsUrl, payload)
        .then(({ data }) => {// 
          this.context.commit(Mutations.SET_LEAD_DOCUMENTS, []);
          this.context.commit(Mutations.SET_POLICY_DOCUMENT_LOADING, false);
          this.context.commit(Mutations.SET_LEAD_DOCUMENTS, data.data);
          // this.isApiCalled= false;
          this.context.commit(Mutations.SET_POLICY_DOCUMENT_DOM_STATUS, true);
          resolve(data);
        })
        .catch((error) => {
          // console.log(error)
          // this.context.commit(Mutations.SET_POLICY_ERROR, error.response.data);
          // this.isApiCalled= false;
          this.context.commit(Mutations.SET_POLICY_DOCUMENT_LOADING, false);
          reject(error);
        });
    });

  }
  
  @Action
  [Actions.GET_ACTIVE_LEAD_POLICY_DOCUMENTS](payload) { // here
    this.context.commit(Mutations.SET_POLICY_DOCUMENT_LOADING, true);
    this.policyUrl.getPolicyDocumentsUrl = "skye/documents/list-active-document";
    return new Promise<any>((resolve, reject) => {
      this.context.commit(Mutations.SET_POLICY_DOCUMENT_DOM_STATUS, false);
      ApiService.post(this.policyUrl.getPolicyDocumentsUrl, payload)
        .then(({ data }) => {// 
          
          this.context.commit(Mutations.SET_LEAD_DOCUMENTS, []);
          this.context.commit(Mutations.SET_POLICY_DOCUMENT_LOADING, false);
          this.context.commit(Mutations.SET_LEAD_DOCUMENTS, data.data);
          // this.isApiCalled= false;
          this.context.commit(Mutations.SET_POLICY_DOCUMENT_DOM_STATUS, true);
          resolve(data);
        })
        .catch((error) => {
          // console.log(error)
          // this.context.commit(Mutations.SET_POLICY_ERROR, error.response.data);
          // this.isApiCalled= false;
          this.context.commit(Mutations.SET_POLICY_DOCUMENT_LOADING, false);
          reject(error);
        });
    });

  }
  @Action
  [Actions.GET_TEMP_POLICY_DOCUMENT](payload) {

    this.context.commit(Mutations.SE_TEMP_LOADING_DOC, true);

    this.policyUrl.listTempPolicyDocumentsUrl = "/skye/documents/list-temp-document";
    if (payload.resetTempDoc) {
      this.context.commit(Mutations.SET_TEMP_DOCUMENTS, []);
    }

    return new Promise<any>((resolve, reject) => {
      ApiService.post(this.policyUrl.listTempPolicyDocumentsUrl, payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_TEMP_DOCUMENTS, data.data.documents);
          this.context.commit(Mutations.SE_TEMP_LOADING_DOC, false);
          this.context.commit(Mutations.SET_EDIT_CANCELLATION_LOADING, false);
          resolve(data);
        })
        .catch((error) => {
          // console.log(error.response.data) 
          this.context.commit(Mutations.SE_TEMP_LOADING_DOC, false);
          this.context.commit(Mutations.SET_DOCUMENT_ERROR, error.response.data);
          reject(error.response.data);
          // this.context.commit(Mutations.SET_POLICY_ERROR, error);
          // this.isApiCalled= false;
        });
    });

  }
  @Action
  [Actions.GET_TEMP_POLICY_CANCEL_DOCUMENT](payload) {

    this.context.commit(Mutations.SE_TEMP_LOADING_DOC, true);

    this.policyUrl.listTempPolicyDocumentsUrl = "/skye/documents/list-temp-document";
    if (payload.resetTempDoc) {
      this.context.commit(Mutations.SET_TEMP_CANCEL_DOCUMENTS, []);
    }

    return new Promise<any>((resolve, reject) => {
      ApiService.post(this.policyUrl.listTempPolicyDocumentsUrl, payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_TEMP_CANCEL_DOCUMENTS, data.data.documents);
          this.context.commit(Mutations.SE_TEMP_LOADING_DOC, false);
          this.context.commit(Mutations.SET_EDIT_CANCELLATION_LOADING, false);
          resolve(data);
        })
        .catch((error) => {
          // console.log(error.response.data) 
          this.context.commit(Mutations.SE_TEMP_LOADING_DOC, false);
          this.context.commit(Mutations.SET_DOCUMENT_ERROR, error.response.data);
          reject(error.response.data);
          // this.context.commit(Mutations.SET_POLICY_ERROR, error);
          // this.isApiCalled= false;
        });
    });

  }

  @Action
  [Actions.DELETE_TEMP_DOCUMENT](payload) {


    this.policyUrl.deleteTempPolicyDocumentsUrl = "/skye/documents/delete-temp-document";

    return new Promise<any>((resolve, reject) => {
      ApiService.post(this.policyUrl.deleteTempPolicyDocumentsUrl, payload)
        .then(({ data }) => {
          // this.context.commit(Mutations.SET_TEMP_DOCUMENTS, data.data.documents);
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_POLICY_ERROR, response);
          // this.isApiCalled= false;
          reject(response);
        });
    });

  }
  @Action
  [Actions.UPDATE_TEMP_DOCUMENT](payload) {


    this.policyUrl.updateTempPolicyDocumentsUrl = "/skye/documents/update-temp-document";

    return new Promise<any>((resolve, reject) => {
      ApiService.post(this.policyUrl.updateTempPolicyDocumentsUrl, payload)
        .then(({ data }) => {
          // this.context.commit(Mutations.SET_TEMP_DOCUMENTS, data.data.documents);
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_POLICY_ERROR, response);
          // this.isApiCalled= false;
          reject(response);
        });
    });

  }

  @Action
  [Actions.GET_POLICY_MAIL_LIST](payload) {


    this.policyUrl.getPolicyMailListUrl = "/skye/policies/list-email-activiy";

    return new Promise<any>((resolve, reject) => {
      ApiService.post(this.policyUrl.getPolicyMailListUrl, payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_POLICY_MAIL_LIST, data.data.activites);
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_POLICY_ERROR, response);
          // this.isApiCalled= false;
          reject(response);
        });
    });

  }
  @Action
  [Actions.SEND_CUSTOMER_EMAIL](payload) {


    this.policyUrl.sendCustomerEmail = "skye/policies/send-email-customer";

    return new Promise<any>((resolve, reject) => {
      this.context.commit(Mutations.SET_CUSTOMER_EMAIL_DISABLE_BUTTON, true);
      ApiService.post(this.policyUrl.sendCustomerEmail, payload)
        .then(({ data }) => {
          // this.context.commit(Mutations.SET_POLICY_MAIL_LIST, data.data.activites);
          this.context.commit(Mutations.SET_CUSTOMER_EMAIL_DISABLE_BUTTON, false);
          notif.simple('Email Sent', 'success', data.message)
          resolve(data);
        })
        .catch(({ response }) => {
          // this.context.commit(Mutations.SET_POLICY_ERROR, response);
          notif.simple('Please Note', 'warning', response.data.message, 0)
          // this.isApiCalled= false;
          reject(response);
        });
    });

  }
  @Action
  [Actions.SEND_BROKER_EMAIL](payload) {


    this.policyUrl.sendBrokerEmail = "skye/policies/send-email-insurance-broker";

    return new Promise<any>((resolve, reject) => {
      this.context.commit(Mutations.SET_BROKER_EMAIL_DISABLE_BUTTON, true);
      ApiService.post(this.policyUrl.sendBrokerEmail, payload)
        .then(({ data }) => {
          // this.context.commit(Mutations.SET_POLICY_MAIL_LIST, data.data.activites);
          this.context.commit(Mutations.SET_BROKER_EMAIL_DISABLE_BUTTON, false);
          notif.simple('Email Sent', 'success', data.message)
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_BROKER_EMAIL_DISABLE_BUTTON, false);
          // this.context.commit(Mutations.SET_POLICY_ERROR, response);
          notif.simple('Please Note', 'warning', response.data.message, 0)
          // this.isApiCalled= false;
          reject(response);
        });
    });

  }

  @Action
  [Actions.EXPORT_POLICY_ASSIGNED](payload) {
    return new Promise<any>((resolve, reject) => { // 
      this.policyUrl.exportPolicyAssigned = "/skye/policies/export-policies";
      this.context.commit(Mutations.SET_DISABLE_BUTTON, true);
      ApiService.post(this.policyUrl.exportPolicyAssigned, payload)
        .then(({ data }) => {
          debugger
          this.context.commit(Mutations.SET_EXPORT_DETAILS, data.data.data);
          this.context.commit(Mutations.SET_DISABLE_BUTTON, false);
          resolve(data);

        })
        .catch(({ response }) => {// 
          this.context.commit(Mutations.SET_POLICY_ERROR, response);
          reject(response);
        });
    });
  }
  @Action
  [Actions.TRANSFER_SALE](payload) {

    return new Promise<any>((resolve, reject) => { // 
      this.policyUrl.transferSale = "/skye/policies/transfer-sale";

      this.context.commit(Mutations.SET_DISABLE_TRANSFER_BUTTON, true);
      ApiService.post(this.policyUrl.transferSale, payload)
        .then(({ data }) => {
          debugger
          this.context.commit(Mutations.SET_EXPORT_DETAILS, data.data.data);
          this.context.commit(Mutations.SET_DISABLE_TRANSFER_BUTTON, false);
          resolve(data);

        })
        .catch(({ response }) => {// 
          this.context.commit(Mutations.SET_POLICY_ERROR, response);
          reject(response);
        });
    });
  }
  @Action
  [Actions.TRANSFER_UNDERWRITER](payload) {

    return new Promise<any>((resolve, reject) => { // 
      this.policyUrl.transferUnderwriter = "/skye/policies/transfer-underwriter";

      this.context.commit(Mutations.SET_DISABLE_TRANSFER_BUTTON, true);
      ApiService.post(this.policyUrl.transferUnderwriter, payload)
        .then(({ data }) => {
          debugger
          this.context.commit(Mutations.SET_EXPORT_DETAILS, data.data.data);
          this.context.commit(Mutations.SET_DISABLE_TRANSFER_BUTTON, false);
          resolve(data);

        })
        .catch(({ response }) => {// 
          this.context.commit(Mutations.SET_POLICY_ERROR, response);
          reject(response);
        });
    });
  }
  @Action
  [Actions.CANCEL_POLICY_REQUEST](payload) {

    return new Promise<any>((resolve, reject) => { // 
      this.policyUrl.cancelPolicyRequest = "/skye/policies/cancel-policy";

      // this.context.commit(Mutations.SET_DISABLE_BUTTON, true);
      ApiService.post(this.policyUrl.cancelPolicyRequest, payload)
        .then(({ data }) => {
          // debugger
          // this.context.commit(Mutations.SET_EXPORT_DETAILS, data.data.data);
          // this.context.commit(Mutations.SET_DISABLE_BUTTON, false);
          resolve(data);

        })
        .catch(({ response }) => {// 
          // this.context.commit(Mutations.SET_DISABLE_BUTTON, false);
          this.context.commit(Mutations.SET_POLICY_ERROR, response);
          resolve(response);
        });
    });
  }

  @Action
  [Actions.GET_POLICY_CANCELLATION_DOCUMENT](payload) {
    this.context.commit(Mutations.SET_POLICY_CANCELLATION_DOCUMENTS, []);
    
    return new Promise<any>((resolve, reject) => { // 
      this.policyUrl.cancelPolicyDocuments = "/skye/policies/list-cancel-policy-doc";

      this.context.commit(Mutations.SET_POLICY_DOCUMENT_DOM_STATUS, false);
      ApiService.post(this.policyUrl.cancelPolicyDocuments, payload)
        .then(({ data }) => {
          debugger
          this.context.commit(Mutations.SET_POLICY_CANCELLATION_DOCUMENTS, data.data.documents);
          this.context.commit(Mutations.SET_POLICY_DOCUMENT_DOM_STATUS, true);
          resolve(data);

        })
        .catch(({ response }) => {// 
          this.context.commit(Mutations.SET_POLICY_DOCUMENT_DOM_STATUS, true);
          this.context.commit(Mutations.SET_POLICY_ERROR, response);
          reject(response);
        });
    });
  }
  @Action
  [Actions.UNDERWRITTER_CANCEL_POLICY_REQUEST](payload) {

    return new Promise<any>((resolve, reject) => { // 
      this.policyUrl.underwritterCancelPolicy = "/skye/policies/edit-cancel-policy";

      this.context.commit(Mutations.SET_DISABLE_BUTTON, true);
      ApiService.post(this.policyUrl.underwritterCancelPolicy, payload)
        .then(({ data }) => {
          debugger
          // this.context.commit(Mutations.SET_POLICY_CANCELLATION_DOCUMENTS, data.data.documents);
          this.context.commit(Mutations.SET_DISABLE_BUTTON, false);
          resolve(data);

        })
        .catch(({ response }) => {// 
          const serverError = response.data.message;
          if(serverError.includes('22003')) {
              notif.simple("Please Note", "warning", 'Incorrect amount field entered.', 0);
          }

          this.context.commit(Mutations.SET_DISABLE_BUTTON, false);
          this.context.commit(Mutations.SET_POLICY_ERROR, response);
          reject(response);
        });
    });
  }

  @Action
  [Actions.CANCEL_POLICY_APPROVAL](payload) {

    return new Promise<any>((resolve, reject) => { // 
      this.policyUrl.editCancelPolicy = "/skye/policies/edit-cancellation";

      this.context.commit(Mutations.SET_CANCEL_LOADING, true);
      this.context.commit(Mutations.SET_DISABLE_BUTTON, true);
      ApiService.post(this.policyUrl.editCancelPolicy, payload)
        .then(({ data }) => {
          debugger
          // this.context.commit(Mutations.SET_POLICY_CANCELLATION_DOCUMENTS, data.data.documents);
          this.context.commit(Mutations.SET_DISABLE_BUTTON, false);
          this.context.commit(Mutations.SET_CANCEL_LOADING, false);
          resolve(data);
        })
        .catch(({ response }) => {// 
          this.context.commit(Mutations.SET_DISABLE_BUTTON, false);
          let data = '';

          Object.entries(response.data.data).forEach(element => {
              data += '<p>' + element[1] + '</p>'
          });
          
          data += '';

          notif.simple(response.data.message, 'warning', data, 0)
          
          const serverError = response.data.message;
          if(serverError.includes('22003')) {
            notif.simple("Please Note", "warning", 'Incorrect amount field entered.', 0);
        }

          this.context.commit(Mutations.SET_CANCEL_LOADING, false);
          this.context.commit(Mutations.SET_POLICY_ERROR, response);
          resolve(response);
        });
    });
  }
  @Action
  [Actions.POLICY_REFUND_REQUEST](payload) {

    return new Promise<any>((resolve, reject) => { // 
      this.policyUrl.refundPolicyRequest = "/skye/policies/refund";
      const error = {
        data: {
          message: ''
        }
      }
      this.context.commit(Mutations.SET_POLICY_ERROR, error);

      this.context.commit(Mutations.SET_DISABLE_BUTTON, true);
      ApiService.post(this.policyUrl.refundPolicyRequest, payload)
        .then(({ data }) => {
          debugger
          // this.context.commit(Mutations.SET_POLICY_CANCELLATION_DOCUMENTS, data.data.documents);
          this.context.commit(Mutations.SET_DISABLE_BUTTON, false);
          resolve(data);

        })
        .catch(({ response }) => {// 
          // console.log(response)
          if(response.data.message != 'total refund amount must not greater than Policy Price') {
            notif.simple('Policy Refund', 'warning', `<p>${response.data.message}</p>`)
          } else {
            this.context.commit(Mutations.SET__DISCREPANCY_MODAL_TEXT_ERROR, response.data.message);
          }
          this.context.commit(Mutations.SET_DISABLE_BUTTON, false);
          reject(response);
        });
    });
  }

  @Action
  [Actions.REFUND_POLICY_APPROVAL](payload) {

    return new Promise<any>((resolve, reject) => { // 
      this.policyUrl.refundPolicyApproval = "/skye/policies/edit-refund";

      this.context.commit(Mutations.SET_DISABLE_BUTTON, true);
      ApiService.post(this.policyUrl.refundPolicyApproval, payload)
        .then(({ data }) => {
          debugger
          // this.context.commit(Mutations.SET_POLICY_CANCELLATION_DOCUMENTS, data.data.documents);
          this.context.commit(Mutations.SET_DISABLE_BUTTON, false);
          resolve(data);

        })
        .catch(({ response }) => {// 
          let data = '';

          Object.entries(response.data.data).forEach(element => {
              data += '<p>' + element[1] + '</p>'
          });
          
          data += '';

          if(response.data.message != 'total refund amount must not greater than invoice amount') {
            notif.simple(response.data.message, 'warning', data, 0)
          } else {
            this.context.commit(Mutations.SET__DISCREPANCY_MODAL_TEXT_ERROR, response.data.message);
          }

          this.context.commit(Mutations.SET_DISABLE_BUTTON, false);
          // this.context.commit(Mutations.SET_POLICY_ERROR, response);
          resolve(response);
        });
    });
  }

  @Action
  [Actions.GET_CANCELLED_POLICIES_LIST](payload) { // 
    // this.isApiCalled = true;
    return new Promise<any>((resolve, reject) => { // 

      this.policyUrl.getCancelledPolicyList = "skye/policies/list-cancelled-policies";
      ApiService.post(this.policyUrl.getCancelledPolicyList, payload)
        .then(({ data }) => {// 
          this.context.commit(Mutations.SET_CANCELLED_POLICIES_LIST, data.data.policies);
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_POLICY_ERROR, response.data);
          reject(response);
        });
    });
  }

  @Action
  [Actions.GET_PREVIOUS_POLICY_STATUS](payload) {
    // this.isApiCalled = true;
    return new Promise<any>((resolve, reject) => { // 

      this.policyUrl.getPreviousPolicySatus = "skye/policies/get-policy-lead";
      ApiService.post(this.policyUrl.getPreviousPolicySatus, payload)
        .then(({ data }) => {// 
          this.context.commit(Mutations.SET_PREVIOUS_POLICIES_STATUS, data.data.policy);
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_POLICY_ERROR, response.data);
          reject(response);
        });
    });
  }
  @Action
  [Actions.GET_PREVIOUS_POLICY_DOCUMENTS](payload) {
    // this.isApiCalled = true;
    return new Promise<any>((resolve, reject) => { // 

      this.policyUrl.getPreviousPolicyDocuments = "/skye/policies/list-previous-year-lead-policy-documents";
      ApiService.post(this.policyUrl.getPreviousPolicyDocuments, payload)
        .then(({ data }) => {//
          if(data.data?.documents && data.data?.documents.length == 0) {
            notif.simple('Not Found', 'warning', 'Kindly upload documents again as the previous documents do not exist.', 0);
          } else {
            this.context.commit(Mutations.SET_TEMP_DOCUMENTS, data.data.documents);
            resolve(data);
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_POLICY_ERROR, response.data);
          reject(response);
        });
    });
  }
  @Action
  async [Actions.GET_OCR_VALUE] (payload) {
    // return new Promise((resolve, reject) => { 

    //   ApiService.post('/skye/policies/ocr-reader', payload)
    //     .then((data) => {
    //       resolve(data);
    //     })
    //     .catch((err) => {
    //       reject(err);
    //     });
    // });
    try {
      const data = await ApiService.post('/skye/policies/ocr-reader', payload);
      return data.data.data.result;
    } catch(error: any) {
      notif.simple('OCR', 'warning', 'Incorrect file format. Please upload PDF file only', 0);
      return error.response;
    }
  }
  @Action
  [Actions.REUPLOAD_DOCUMENT](payload) {
    // this.isApiCalled = true;
    return new Promise<any>((resolve, reject) => { // 

      this.policyUrl.reUploadTempDocument = "/skye/documents/update-temp-policy-document";
      ApiService.post(this.policyUrl.reUploadTempDocument, payload)
        .then(({ data }) => {// 
          // this.context.commit(Mutations.SET_PREVIOUS_POLICY_DOCUMENTS, data.data.documents);
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_POLICY_ERROR, response.data);
          reject(response);
        });
    });
  }
  @Action
  [Actions.GET_POLICY_LOGS](payload) {
    // this.isApiCalled = true;
    return new Promise<any>((resolve, reject) => { // 
      this.context.commit(Mutations.SET_POLICY_LOGS, []);
      this.policyUrl.reUploadTempDocument = "/skye/policies/list-policy-logs";
      ApiService.post(this.policyUrl.reUploadTempDocument, payload)
        .then((result) => {// 
          console.log(result.data.data.logs)
          this.context.commit(Mutations.SET_POLICY_LOGS, result.data.data.logs);
          resolve(result.data);
        })
        .catch(({ response }) => {
          
          reject(response);
        });
    });
  }

  @Action
  [Actions.UPDATE_DATA_POLICY](payload) {

    return new Promise<any>((resolve, reject) => {
      this.policyUrl.updatePolicyDataUrl = "/skye/policies/update-data";

      ApiService.post(this.policyUrl.updatePolicyDataUrl, payload)
        .then(({ data }) => {
          resolve(data);
          notif.simple("Policy updated", "success", "Policy data has been updated successfully!");

        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_POLICY_ERROR, response);
          // this.isApiCalled= false;
          reject(response);
        });
    });

  }

  @Action
  async [Actions.GET_CHILD_INVOICES_BY_POLICY](payload) {
    try {
      const result = await ApiService.post('/skye/policies/get-child-invoices', payload);
      this.context.commit(Mutations.SET_CHILD_INVOICES_BY_POLICY, result.data.data.policies);
      return result.data.data.policies;
    } catch(err) {
      console.log(err);
    }
  }

  @Action
  async [Actions.GET_EXTENSIONS](payload) {
      this.context.commit(Mutations.SET_EXTENSION_IS_OPEN, false);
      this.context.commit(Mutations.SET_EXTENSIONS, []);
      try {
        const result = await ApiService.post('/skye/documents/list/amends', payload);
        const amends = result.data.data.amend_documents;
        this.context.commit(Mutations.SET_EXTENSIONS, amends);
        this.context.commit(Mutations.SET_EXTENSION_IS_OPEN, true);
      return amends
    } catch (error) {
      return error
    }
  }

  @Action
  async [Actions.UPLOAD_AMEND_DOCUMENT](payload) {
      try {
        const result = await ApiService.post('/skye/documents/add/amend', payload);
        this.context.commit(Mutations.SET_EXTENSIONS, result.data.data.list);

      } catch (error) {
        return error
      }
  }

  @Mutation
  [Mutations.SET_POLICY_MAIL_LIST](payload) {
    this.policyEmailList = payload;
  }

  @Mutation
  [Mutations.SET_POLICY_DOCUMENT_LOADING](payload) {
    this.policyDocumentLoading = payload;
  }

  @Mutation
  [Mutations.SET_TEMP_DOCUMENTS](payload) {
    this.policyTempDocuments = payload;
  }

  @Mutation
  [Mutations.SET_TEMP_CANCEL_DOCUMENTS](payload) {
    this.policyTempCancelDocuments = payload;
  }
  
  @Mutation
  [Mutations.SET_POLICY_DOCUMENTS](payload) {

    this.policyDocuments = payload;
  }
  @Mutation
  [Mutations.SET_ALL_POLICY_DOCUMENTS](payload: any) {
    console.log({payload})
    this.allPolicyDocuments = payload;
  }
  
  @Mutation
  [Mutations.SET_LEAD_DOCUMENTS](payload) {
    this.leadDocuments = payload;
  }
  @Mutation
  [Mutations.SET_DOCUMENT_ERROR](payload) {
    this.documentError = payload;
  }

  @Mutation
  [Mutations.SET_POLICY_LIST](payload) {

    this.policyList = payload.data;
    this.paginationObj.links = payload.links;
    this.paginationObj.first_page_url = payload.first_page_url;
    this.paginationObj.last_page_url = payload.last_page_url;
    this.paginationObj.from = payload.from;
    this.paginationObj.to = payload.to;
    this.paginationObj.next_page_url = payload.next_page_url;
    this.paginationObj.prev_page_url = payload.prev_page_url;
    this.paginationObj.total = payload.total;
  }
  @Mutation [Mutations.SET_POLICY_ERROR](payload) {
    this.errors = payload.data.message;
    for (const key in payload.data.data) {
      this.errorDetails = payload.data.data[key];
    }

  }
  @Mutation [Mutations.RESET_POLICY_ERROR]() {
    this.errors = '';
  }
  @Mutation
  [Mutations.SET_LEAD_POLICY_LIST](payload) {

    this.leadPolicyList = payload;
  }

  @Mutation
  [Mutations.SET_DEBIT_VALUE](payload) {
    this.debitValue = payload
  }

  @Mutation
  [Mutations.SET_POLICY_LIST_COUNT](payload) {

    this.policyListCount = payload;
    //   this.paginationObj = payload;
  }
  @Mutation
  [Mutations.SET_EXPORT_DETAILS](payload) {
    // 
    this.export = payload;
  }
  @Mutation
  [Mutations.SET_POLICY_DETAILS](payload) {

    this.policyDetails = payload;
  }
  @Mutation
  [Mutations.SET_POLICY_CANCELLATION_DOCUMENTS](payload) {

    this.policyCancellationDocuments = payload;
  }
  @Mutation
  [Mutations.SET_CANCELLED_POLICIES_LIST](payload) {

    this.policyCancelledList = payload;
  }
  @Mutation
  [Mutations.SET_PREVIOUS_POLICIES_STATUS](payload) {
    this.PreviousPolicyStatus = payload;
  }
  @Mutation
  [Mutations.SET_PREVIOUS_POLICY_DOCUMENTS](payload) {
    this.PreviousPolicyDocuments = payload;
  }
  @Mutation
  [Mutations.SET_MODAL](payload) {
    this.openModal = payload
  }
  @Mutation
  [Mutations.SET_MODAL_LOADING]() {
    this.isLoading = !this.isLoading
  }
  @Mutation
  [Mutations.SET_MODAL_VALUE](payload) {
    this.openModalValue = payload
  }
  @Mutation
  [Mutations.SET_POLICY_ACTIVE]() {
    this.policyIsActive = true
  }
  @Mutation
  [Mutations.SET_POLICY_LOGS](payload) {
    this.policyLeadLogs = payload
  }
  @Mutation
  [Mutations.SE_TEMP_LOADING_DOC](payload) {
    this.loadingTempDocument = payload
  }
  @Mutation
  [Mutations.SET_PA_LOADING](payload) {
    this.paLoading = payload
  }
  @Mutation
  [Mutations.SET_EDIT_CANCELLATION_LOADING](payload) {
    this.editCancellationLoading = payload
  }
  @Mutation
  [Mutations.SET__REFUND_MODAL](payload) {
    this.refundModal = payload
  }
  @Mutation
  [Mutations.SET_POLICY_VIEW](payload) {
    this.isPolicyView = payload
  }
  @Mutation
  [Mutations.SET__DISCREPANCY_MODAL](payload) {
    this.discrepancyModal = payload
  }
  @Mutation
  [Mutations.SET__DISCREPANCY_MODAL_TEXT](payload) {
    this.discrepancyModalText = payload
  }
  @Mutation
  [Mutations.SET__DISCREPANCY_MODAL_TEXT_ERROR](payload) {
    this.editPolicyRefundError = payload
  }
  @Mutation
  [Mutations.SET_IS_DISCREPANCY_ERROR](payload) {
    this.isDiscrepancyAtBackEnd = payload
  }

  @Mutation
  [Mutations.SET_CHILD_INVOICES_BY_POLICY](payload) {
    this.childInvoice = payload
  }
  @Mutation
  [Mutations.SET_CANCEL_MODAL](payload) {
    this.cancelModal = payload
  }

  @Mutation
  [Mutations.SET_EXTENSION_IS_OPEN](payload) {
    this.extensionOpen = payload
  }

  @Mutation
  [Mutations.SET_CATEGORY_REFUND](payload) {
    this.category_refund = payload
  }

  @Mutation
  [Mutations.SET_REFUND_INVOICE_ID](payload) {
    this.refundInvoiceId = payload
  }

  @Mutation
  [Mutations.SET_EXTENSIONS](payload) {
    this.extensions = payload
  }
  get getpolicyDetails(): IPolicyDetails {
    return this.policyDetails;
  }
  get getpolicyList(): IPolicyList {
    return this.policyList;
  }
  get getPolicyErrors(): any {
    return this.errors;
  }
  get getPolicyErrorDetails(): any {
    return this.errorDetails;
  }
  get getPolicyDocs(): Array<IPolicyDocuments> {
    return this.policyDocuments;
  }
  get getTempPolicyDocs() {
    return this.policyTempDocuments;
  }
  get getTempPolicyCancelDocs() {
    return this.policyTempCancelDocuments;
  }
  get getEmailPolicyList(): IPolicyEmailList {
    return this.policyEmailList;
  }
  get getPolicyCount(): IPolicyCount {
    return this.policyListCount;
  }
  get getExportApproval(): IExport {
    return this.export;
  }
  get getPolicyCancellationDocuments(): Array<IPolicyTempDocuments> {
    return this.policyCancellationDocuments;
  }
  get getCancelledPolicies(): IPolicyList {
    return this.policyCancelledList;
  }
  get getPreviousYearPolicyDetails(): IPreviousPolicySatus {
    return this.PreviousPolicyStatus;
  }
  get getPreviousPolicyDocumentList(): IPolicyDocuments {
    return this.PreviousPolicyDocuments;
  }
  
  get getModal(): boolean {
    return this.openModal
  }
  
  get getModalValue(): ModalValue {
    return this.openModalValue
  }
  
  get getModalLoading(): boolean {
    return this.isLoading
  }
  
  get getDocumentError() {
    return this.documentError
  }

  get getDebitValue() : any {
    return this.debitValue
  }

  get getLeadPolicyLists() : any {
    return this.leadPolicyList
  }

  get getPolicyIsActive() : any {
    return this.policyIsActive;
  }

  get getPolicyLeadLogs() : any {
    return this.policyLeadLogs;
  }

  get getPolicyPaginationObject() {
    return this.paginationObj;
  }

  get getLoadingTempDocument() {
    return this.loadingTempDocument;
  }

  get getPaLoading() {
    return this.paLoading;
  }

  get getEditCancellationLoading() {
    return this.editCancellationLoading;
  }

  get getRefundModal() {
    return this.refundModal;
  } 
  
  get getPolicyView(): boolean {
    return this.isPolicyView;
  }

  get getDiscrepancyModal() {
    return this.discrepancyModal;
  }

  get getDiscrepancyModalText() {
    return this.discrepancyModalText;
  }

  get getEditPolicyRefundError() {
    return this.editPolicyRefundError;
  }

  get getIsDiscrepancyAtBackEnd() {
    return this.isDiscrepancyAtBackEnd;
  }

  get getPolicyDocumentLoading() {
    return this.policyDocumentLoading;
  }
  get getAllPolicyDocuments():any {
    return this.allPolicyDocuments;
  }
  get getLeadDocumentList () : any {
    return this.leadDocuments;
  }

  get getCancelModal() : boolean {
    return this.cancelModal;
  }
  
  get getExtensionOpen () : any {
    return this.extensionOpen;
  }
  get getExtensions () : any {
    return this.extensions;
  }
  get getCategoryRefund () : any {
    return this.category_refund;
  }

  get getRefundInvoiceId () {
    return this.refundInvoiceId
  }
}
