
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

import ApiService from "@/core/services/ApiService";
import Invoice from "@/models/car/IInvoiceList";
import IPaginationInfo from "@/models/IPaginationInfo";
import { notif } from '@/store/stateless/store';
import EmailService from "@/core/services/car/EmailService";

export interface ManualOrderInfo {
    manualOrderUrl: ManualOrderUrlInfo;
    manualOrderList: Invoice;
    paginationObj: IPaginationInfo;
}

export interface ManualOrderUrlInfo {
    getManualOrderList: string;
    getLeadsByCustomer:string;
    addTempManualOrder:string;
    addManualOrder:string;
    showManualOrder:string;
    deleteManualOrder:string;
}


@Module
export default class ManualOrderModule extends VuexModule implements ManualOrderInfo {
    manualOrderUrl = {} as ManualOrderUrlInfo;
    manualOrderList = {} as Invoice;
    paginationObj = {
        current_page: 1,
        data: [],
        first_page_url: "",
        from: 1,
        last_page: 1,
        last_page_url: "",
        links: [],
        next_page_url: "",
        path: "",
        per_page: "",
        prev_page_url: "",
        to: 1,
        total: 1,
    } as IPaginationInfo;
    leads = {}; 
    editManualOrderObj = {};
    errors = [];
    invoice = [];
    openModal = false;
    openModalValue = {};
    isLoading = false;
    openInvoiceModal = false;
    openInvoiceModalValue = {};
    moLoading = true
  
    @Action
    [Actions.GET_MANUAL_ORDER_LIST](payload) {
        this.context.commit(Mutations.SET_MO_LOADING, true);
        this.context.commit(Mutations.SET_MANUAL_ORDER_LIST, {
            data: [],
            links: [],
            first_page_url: '',
            last_page_url: '',
            next_page_url: '',
            prev_page_url: '',
            from: 0,
            to: 0,
            total: 0
        });
        return new Promise<any>((resolve, reject) => {
            this.manualOrderUrl.getManualOrderList = (payload.index == 1) ? "/skye/manual-order/list-manual-orders" : "/skye/manual-order/list-manual-orders?page=" + payload.index;

            ApiService.post(this.manualOrderUrl.getManualOrderList, payload)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_MANUAL_ORDER_ERROR, []);
                    this.context.commit(Mutations.SET_MANUAL_ORDER_LIST, data.data.invoices);
                    this.context.commit(Mutations.SET_MO_LOADING, false);
                    resolve(data);
                })
                .catch(({ response }) => {
                    this.context.commit(Mutations.SET_MO_LOADING, false);
                    // this.context.commit(Mutations.SET_MANUAL_ORDER_ERROR, response.data.errors);
                    reject(response);
                });
        });
    }

    @Action
    [Actions.GET_LEAD_BY_CUSTOMER](payload) {
        return new Promise<any>((resolve, reject) => {
            this.manualOrderUrl.getLeadsByCustomer ="/skye/manual-order/get-customer-leads";

            ApiService.post(this.manualOrderUrl.getLeadsByCustomer, payload)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_MANUAL_ORDER_ERROR, []);
                    this.context.commit(Mutations.SET_LEAD_BY_CUSTOMER, data.data.leads);
                    resolve(data);
                })
                .catch(({ response }) => {
                    // this.context.commit(Mutations.SET_MANUAL_ORDER_ERROR, response.data.errors);
                    reject(response);
                });
        });
    }
    
    @Action
    [Actions.ADD_TEMP_MANUAL_ORDER](payload) {
        return new Promise<any>((resolve, reject) => {
            this.manualOrderUrl.addTempManualOrder ="/skye/manual-order/add-temp-manual-order";

            ApiService.post(this.manualOrderUrl.addTempManualOrder, payload)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_MANUAL_ORDER_ERROR, []);
                    this.context.commit(Mutations.SET_MANUAL_ORDER_INVOICE, data.data.invoice);
                    resolve(data);
                })
                .catch(({ response }) => {
                    this.context.commit(Mutations.SET_MANUAL_ORDER_ERROR, response.data.data);
                    reject(response);
                });
        });
    }

    @Action
    async [Actions.ADD_MANUAL_ORDER](payload) {

        try {
            this.manualOrderUrl.addManualOrder ="/skye/manual-order/add-manual-order";
            const data = await ApiService.post(this.manualOrderUrl.addManualOrder, payload)

            this.context.commit(Mutations.SET_INVOICE, data.data.data.invoice);
            this.context.commit(Mutations.SET_MANUAL_ORDER_ERROR, []);

            return data
        } catch (error: any) {
            if(error.response.status === 400) {
                notif.error('Manual Order Error',error.response.data.message)
                EmailService.sendEmailItTeam({customer_id: error.response.data.data.customer_id});
                
            }

            return error.response
        }

        // return new Promise<any>((resolve, reject) => {

        //     ApiService.post(this.manualOrderUrl.addManualOrder, payload)
        //         .then(({ data }) => {
        //             this.context.commit(Mutations.SET_INVOICE, data.data.invoice);
        //             this.context.commit(Mutations.SET_MANUAL_ORDER_ERROR, []);
        //             resolve(data);
        //         })
        //         .catch((error) => {

        //             if(error.response.status === 422)
        //             {
        //                 let data = '';

        //                 Object.entries(error.response.data.data).forEach(element => {
        //                     data += '<p>' + element[1] + '</p>'
        //                 });
                        
        //                 notif.simple('Please Note', 'warning', data, 0)

        //                 // notif.simple(error.response.data.message, 'warning', data, 0)
        //                 if(data.length > 0)
        //                     notif.simple('Please Note', 'warning', data, 0)
        //                 else
        //                     notif.simple(error.response.data.message, 'warning', data, 0)
        //                 // this.context.commit(Mutations.SET_MANUAL_ORDER_ERROR, error.response);
        //             }

        //             if(error.response.status === 400)
        //             {
        //                 notif.error('Manual Order Error',error.response.data.message)
        //                 EmailService.sendEmailItTeam({customer_id: error.response.data.data.customer_id});
        //                 // this.context.commit(Mutations.SET_INVOICE_MODAL);
        //                 // this.context.commit(Mutations.SET_INVOICE_MODAL_VALUE, {
        //                 //     customer_id:error.response.data.data.customer_id
        //                 // });
        //             }
                    
        //             // this.context.commit(Mutations.SET_MANUAL_ORDER_ERROR, response.data.data);
        //             reject(error);
        //         });
        // });
    }

    @Action
    [Actions.FETCH_MANUAL_ORDER](payload) {
        return new Promise<any>((resolve, reject) => {
            this.manualOrderUrl.showManualOrder = "/skye/manual-order/show-manual-order";
           
            ApiService.post(this.manualOrderUrl.showManualOrder,payload)//,payload
            .then(({data}) => { 
               //  
                this.context.commit(Mutations.SET_MANUAL_ORDER,data.data.invoice);
                resolve(data);
               
            })
            .catch(({ response }) => {
                reject(response);
            });
        })
    }

    @Action
    [Actions.DELETE_MANUAL_ORDER](payload) {
        return new Promise<any>((resolve, reject) => {
            this.manualOrderUrl.deleteManualOrder = "/skye/manual-order/delete-manual-order";
           
            ApiService.post(this.manualOrderUrl.deleteManualOrder,payload)//,payload
            .then(({data}) => { 
               //  
                resolve(data);
               
            })
            .catch(({ response }) => {
                reject(response);
            });
        })
    }
    

    @Mutation
    [Mutations.SET_MANUAL_ORDER_LIST](payload) {
       
        this.manualOrderList = payload.data;
        this.paginationObj.links = payload.links;
        this.paginationObj.first_page_url = payload.first_page_url;
        this.paginationObj.last_page_url = payload.last_page_url;
        this.paginationObj.from = payload.from;
        this.paginationObj.to = payload.to;
        this.paginationObj.next_page_url = payload.next_page_url;
        this.paginationObj.prev_page_url = payload.prev_page_url;
        this.paginationObj.total = payload.total;
    }
    @Mutation
    [Mutations.SET_MANUAL_ORDER_ERROR](error) { 
        const errors = [] as  any;
        for (const key in error) {
      
          errors.push(error[key]);
         
        }
        // console.log('errors',errors)
        this.errors = errors;
        // console.log('this.errors',this.errors)
    }
    @Mutation
    [Mutations.SET_LEAD_BY_CUSTOMER](payload): void {
        this.leads = payload;
    }
    @Mutation
    [Mutations.SET_MANUAL_ORDER](payload): void {
        this.editManualOrderObj = payload;
    }
    @Mutation
    [Mutations.SET_MANUAL_ORDER_INVOICE](payload): void {
        this.invoice = payload;
    }

    @Mutation
    [Mutations.SET_MODAL_VALUE](payload) {
        this.openModalValue = payload
    }

    @Mutation
    [Mutations.SET_INVOICE](payload): void {
        this.invoice = payload;
    }
    @Mutation
    [Mutations.SET_INVOICE_MODAL]() {
        this.openInvoiceModal = !this.openInvoiceModal
    }

    @Mutation
    [Mutations.SET_INVOICE_MODAL_VALUE](payload) {
        this.openInvoiceModalValue = payload
    }

    @Mutation
    [Mutations.SET_MO_LOADING](payload) {
        this.moLoading = payload
    }
   
    get getManualOrder(): Invoice {
        return this.manualOrderList;
    }
 

    get getLeads() {
        return this.leads;
    }
    get getInvoice() {
        return this.invoice;
    }

    get editManualOrder() {
        return this.editManualOrderObj;
    }

    get getManualOrderPaginationObject() {
        return this.paginationObj;
    }

    get fetchManualOrder() {
        return this.invoice;
    }

    get getMoLoading() {
        return this.moLoading;
    }
}
