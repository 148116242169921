// const ID_TOKEN_KEY = "id_token" as string;

// /**
//  * @description get token form localStorage
//  */
// export const getToken = (): string | null => {
//   return window.localStorage.getItem(ID_TOKEN_KEY);
// };

// /**
//  * @description save token into localStorage
//  * @param token: string
//  */
// export const saveToken = (token: string): void => {
//   window.localStorage.setItem(ID_TOKEN_KEY, token);
// };

// /**
//  * @description remove token form localStorage
//  */
// export const destroyToken = (): void => {
//   window.localStorage.removeItem(ID_TOKEN_KEY);
// };

// export default { getToken, saveToken, destroyToken };
import store from "@/store";
import router from "@/router";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { App, computed } from "vue";
// import $ from 'jquery'; 
/**
 * @description service to call HTTP request via Axios
 */
class MasterService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    MasterService.vueInstance = app;
  }

  public static getUserTypes() {// 
    store
      .dispatch(Actions.GET_USER_TYPES)
      .then((data) => { console.log(data); })
      .catch((error) => { console.log(error); })
  }
  /**
   * 
   *  TODO Need to find why why did I add this here!!!!! Ashwin B
   */
  public static addInvoice(payload) {// 
    store
      .dispatch(Actions.ADD_INVOICE, payload)
      .then((data) => {
        router.push({ name: "invoice-list" });
      })
      .catch((error) => { console.log(error); })
  }
  public static getTaskResponse(payload) {// 
    return new Promise<void>((resolve, reject) => {
      store
        .dispatch(Actions.GET_TASK_RESPONSE, payload)
        .then((data) => { resolve(data); })
        .catch((error) => { console.log(error); })
    });

  }
  public static getTaskReasons(payload) {// 
    return new Promise<void>((resolve, reject) => {
      store
        .dispatch(Actions.GET_TASK_REASONS, payload)
        .then((data) => { resolve(data); })
        .catch((error) => { reject(error); })
    });
  }
  public static getDocumentTypes(payload) {// 
    return new Promise<void>((resolve, reject) => {
      store
        .dispatch(Actions.GET_DOC_TYPES,payload)
        .then((data) => { resolve(data); })
        .catch((error) => { reject(error); })
    })
  }
  public static getCanxDocumentTypes(payload) {// 
    return new Promise<void>((resolve, reject) => {
      store
        .dispatch(Actions.GET_CANX_DOC_TYPES,payload)
        .then((data) => { resolve(data); })
        .catch((error) => { reject(error); })
    })
  }

  public static getCancellationReason() {// 
    return new Promise<void>((resolve, reject) => {
      store
        .dispatch(Actions.GET_CANCELLATION_REASON,{})
        .then((data) => { resolve(data); })
        .catch((error) => { reject(error); })
    })
  }
  public static getReasonnReason() {// 
    return new Promise<void>((resolve, reject) => {
      store
        .dispatch(Actions.GET_REFUND_REASON,{})
        .then((data) => { resolve(data); })
        .catch((error) => { 
          //
         })
    })
  }
  
  public static getSearchResult(payload) {// 
    return new Promise<void>((resolve, reject) => {
      store
        .dispatch(Actions.GET_SEARCH_RESULT,payload)
        .then((data) => { resolve(data); })
        .catch((error) => { reject(error); })
    })
  }
  
  public static getSearchResultMo(payload) {// 
    return new Promise<void>((resolve, reject) => {
      store
        .dispatch(Actions.GET_SEARCH_RESULT_MO,payload)
        .then((data) => { resolve(data); })
        .catch((error) => { reject(error); })
    })
  }
  public static getPayToMasters(payload) {// 
    return new Promise<void>((resolve, reject) => {
      store
        .dispatch(Actions.GET_PAYTO_MASTERS,payload)
        .then((data) => { resolve(data); })
        .catch((error) => { reject(error); })
    })
  }
  public static getPaymentTypes() {// 
    return new Promise<void>((resolve, reject) => {
      store
        .dispatch(Actions.GET_PAYMENT_TYPES)

        .then((data) => { resolve(data); })
        .catch((error) => { reject(error); })
    })
  }
  public static getInsurancePaymentType(payload) {// 
    return new Promise<void>((resolve, reject) => {
      store
        .dispatch(Actions.GET_INSURANCE_PAYMET_TYPE,payload)
        .then((data) => { resolve(data); })
        .catch((error) => { reject(error); })
    })
  }

  public static getCallbackRequestList(payload) {
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.GET_CALLBACK_REQUEST_LIST, payload)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        })
    });
  }

  public static getCallbackPetRequestList(payload) {
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.GET_CALLBACK_PET_REQUEST_LIST, payload)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        })
    });
  }

  public static getExpatInsuranceCallBackList(payload) {
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.GET_CALLBACK_EXPAT_REQUEST_LIST, payload)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        })
    });
  }

  public static getContactUs(payload) {
    return new Promise<void>((resolve, reject) => {
        store.dispatch(Actions.GET_CONTACT_US_LIST,payload)
            .then((data) => {
                
                resolve(data);
            })
            .catch((error) => { 
              reject(error);
              console.log(error); 
            });
    });

  }
  public static exportCallbackRequest(payload) {
    return new Promise<void>((resolve, reject) => {
      store
        .dispatch(Actions.EXPORT_CALLBACK_REQUEST, payload)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        })
    });
  }
  public static exportCallbackPetRequest(payload) {
    return new Promise<void>((resolve, reject) => {
      store
        .dispatch(Actions.EXPORT_CALLBACK_PET_REQUEST, payload)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        })
    });
  }

  public static exportCallbackExpatRequest(payload) {
    return new Promise<void>((resolve, reject) => {
      store
        .dispatch(Actions.EXPORT_CALLBACK_EXPAT_REQUEST, payload)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        })
    });
  }

  public static exportContactUsRequest() {
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.EXPORT_CONTACT_US_REQUEST)
        .then((data) => {
          resolve(data);

        })
        .catch((error) => {
          console.log(error);
          reject(error);
        })
    });
  }
  public static getCountryCodes() {
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.GET_COUNTRY_CODE_LIST)
        .then((data) => {
          resolve(data);

        })
        .catch((error) => {
          console.log(error);
          reject(error);
        })
    });
  }

  /* Get Document Logs */
  public static getDocumentLogList(payload) {
    // console.log('index',payload)
    // payload.index = index;
   console.log('payload',payload)
    return new Promise<void>((resolve, reject) => {
      store
        .dispatch(Actions.GET_DOCUMENT_LOG_LIST, payload)
        .then((data) => {

          resolve(data);

        })
        .catch((error) => {
          console.log(error);
          reject(error);
        })
    });

  }

  public static addDocumentLog(data) {
    return new Promise<void>((resolve, reject) => {
      store
        .dispatch(Actions.ADD_DOCUMENT_LOG, data)
        .then((data) => {

          resolve(data);

        })
        .catch((error) => {
          console.log(error);
          reject(error);
        })
    });
  }

  public static closeModal() {
    store.commit(Mutations.SET_OPEN_DOCUMENT_LOGS, false);
  }

  public static getPolicyStatuses(payload = {}) {// 
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.GET_POLICY_STATUS_LIST, payload)
        .then((data) => { resolve(data); })
        .catch((error) => { reject(error); })
    })
  }

  public static getLeadSources() {// 
    return new Promise<void>((resolve, reject) => {
      store
        .dispatch(Actions.GET_LEAD_SOURCES,{})
        .then((data) => { resolve(data); })
        .catch((error) => { reject(error); })
    })
  }

  public static resetSearchResult() {
    store.commit(Mutations.SET_SEARCH_RESULT, []);
  }

  public static getInsuranceProviders() {
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.GET_INSURANCE_PROVIDERS, {})
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        })
    });
  }

  public static getInsurancePolicyListByType(payload) {
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.GET_INSURANCE_POLICY_BY_TYPE_LIST, payload)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        })
    });
  }
  public static getBankDetails() {
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.GET_BANK_DETAILS,[])
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        })
    });
  }

  public static getSplitPaymentTypes() {// 
    return new Promise<void>((resolve, reject) => {
      store
        .dispatch(Actions.GET_SPLIT_PAYMENT_TYPES)
        .then((data) => { resolve(data); })
        .catch((error) => { reject(error); })
    })
  }


  public static async getPolicyStartDateDays() {
    return await store.dispatch(Actions.GET_POLICY_START_DATE_DAYS_RESTRICT)
  }

  public static async getVat() {
    return await store.dispatch(Actions.GET_VAT)
  }

  public static async getCarYears(payload) {
    return await store.dispatch(Actions.GET_CAR_YEARS, payload);
  }

  public static purgeCache() {
    store.commit(Mutations.PURGE_AUTH_SIGNIN)
    window.location.reload();
  }

  public static async checkTrim(payload) { 
    return await store.dispatch(Actions.GET_CHECK_TRIM, payload);
  }

  public static setTrimModal(payload) {
    store.commit(Mutations.SET_CHECK_TRIM, payload);
  }

}
export default MasterService;